<template>
  <div id="alert-page">
    <page-title-card
      :page-title="t('Alerts configuration')"
      :page-description="t('description')"
    >
      <template #button>
        <n-button
          @click.native="handleSave"
          type="primary"
          size="md"
          round
          block
          :visible_for_permissions="[
              { actions: ['PATCH'], path: '/subsystems/ns/events.*' },
            ]"
        >
          {{ t("Save") }}
        </n-button>
      </template>
      <template #refresh>
        <b-container>
          <div class="hr-grey mb-2" />
          <div @click="openModal()" v-if="!updating" class="refresh">
            <i class="now-ui-icons arrows-1_refresh-69" /> {{ t("Update now") }}
          </div>
          <div v-else class="refresh">
            <i class="now-ui-icons loader_refresh spin" />
            {{ t("Updating...") }}
          </div>
        </b-container>
      </template>
    </page-title-card>
    <b-row>
      <b-col cols="12" class="pt-2">
        <b-card>
          <b-row>
            <b-col cols="9">
              <h4 class="alerts-header">
                <svgicon icon="alerts-list" width="24" height="24" />
                <span> {{ t("Alerts List") }}</span>
              </h4>
            </b-col>
            <b-col cols="3">
              <n-button
                type="primary"
                size="md"
                round
                block
                id="add-button"
                @click.native="handleNewAlert"
                class="add-button"
                :visible_for_permissions="[
                  { actions: ['POST'], path: '/subsystems/ns/alerts' },
                ]"
              >
                <i><svgicon class="icon" icon="icon-new" /></i>
                {{ t("New") }}
              </n-button>

              <!--<div id='add-button' @click="handleNewAlert" class="add-button-div"> <svgicon class="add-button" icon="icon-new" width="28" height="28" /></div>-->
            </b-col>
          </b-row>

          <b-card-body style="padding: 0px">
            <paginated-table
              :rows="rows"
              :columns="columns"
              :actions="actions"
              @item-edit="handleEditClick"
              @item-remove="handleRemoveClick"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" class="pt-2">
        <b-card>
          <h4 class="alerts-header">
            <svgicon
              icon="filtering-log-configuration"
              width="24"
              height="24"
            />
            <span>{{ t("Default Configuration") }}</span>
          </h4>
          <b-row>
            <b-col md="3" lg="3" xl="3"> </b-col>
            <b-col md="2" lg="2" xl="2">
              {{ t("Record in System Log") }}
            </b-col>
            <b-col md="2" lg="2" xl="2">
              {{ t("Record in internal Log") }}
            </b-col>
            <b-col md="2" lg="2" xl="2"> {{ t("Send SNMP Trap") }} </b-col>
            <b-col md="2" lg="2" xl="2"> {{ t("Send via e-mail") }} </b-col>
          </b-row>
          <div v-for="(conf, name) in configurations" :key="name">
            <b-row>
              <b-col md="3" lg="3" xl="3"> {{ name }} </b-col>
              <b-col md="2" lg="2" xl="2">
                <n-switch
                  :key="name + 'system'"
                  v-model="conf.system_log"
                  @input="handleToggle($event, name, 'system')"
                  :visible_for_permissions="[{ actions: ['PATCH'], path: '/subsystems/ns/events.*' }]"
              /></b-col>
              <b-col md="2" lg="2" xl="2">
                <n-switch
                  :key="name + 'internal'"
                  v-model="conf.internal_log"
                  @input="handleToggle($event, name, 'internal')"
                  :visible_for_permissions="[{ actions: ['PATCH'], path: '/subsystems/ns/events.*' }]"
              /></b-col>
              <b-col md="2" lg="2" xl="2">
                <n-switch
                  :key="name + 'snmp'"
                  v-model="conf.SNMP"
                  @input="handleToggle($event, name, 'snmp')"
                  :visible_for_permissions="[{ actions: ['PATCH'], path: '/subsystems/ns/events.*' }]"
              /></b-col>
              <b-col md="2" lg="2" xl="2">
                <n-switch
                  :key="name + 'email'"
                  v-model="conf.mail"
                  @input="handleToggle($event, name, 'email')"
                  :visible_for_permissions="[{ actions: ['PATCH'], path: '/subsystems/ns/events.*' }]"
              /></b-col>
            </b-row>
          </div>
          <b-row v-if="emailSelected">
            <mail-configuration :model="model"></mail-configuration>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row> </b-row>
    <b-modal
      id="modal-new"
      ref="modal"
      :title="t('Alert')"
      @ok="handleOkModal"
      @show="resetModal"
      @hidden="resetModal"
    >
      {{ t("Are you sure") }}
    </b-modal>
    <message-configuration
      :messages="messages"
      :infoTitle="t('selected_exception_configuration')"
      :selectionTitle="t('exception_configuration')"
      :visible_for_permissions="[{ actions: ['PATCH'], path: '/subsystems/ns/events.*' }]"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { PaginatedTable } from "@/components";
import { Switch } from "@/components";
import MessageConfiguration from "./components/MessageConfiguration";
import {
  prepareEventList,
  prepareSaveEventList,
  prepareSaveDefaultEventList,
  prepareEventDefaultList,
} from "./alertUtils.js";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
import MailConfiguration from "./components/MailConfiguration";
import PageTitleCard from "../../components/AsmConfigurations/PageTitleCard";

locale.use(lang);

export default {
  name: "page-alert",
  components: {
    MailConfiguration,
    [PaginatedTable.name]: PaginatedTable,
    [Switch.name]: Switch,
    "message-configuration": MessageConfiguration,
    PageTitleCard
  },
  data() {
    return {
      search: false,
      updating: false,
      alert_id: "",
      // switch
      model: {
        subject: "",
        sender: "",
        addresses: "",
      },
      // table
      columns: [
        {
          prop: "name",
          label: "alertName",
          minWidth: 125,
        },
        {
          prop: "event",
          label: "alertEvent",
          minWidth: 125,
        },
        {
          prop: "type",
          label: "alertType",
          minWidth: 125,
        },
      ],
      actions: {
        minWidth: 100,
        label: "Actions",
        fixed: "right",
        items: [
          {
            type: "warning",
            icon: "now-ui-icons business_badge",
            event: "item-edit",
            action: "Edit",
          },
          {
            type: "danger",
            size: "sm",
            icon: "now-ui-icons ui-1_simple-remove",
            event: "item-remove",
            action: "Remove",
            confirm: true,
            message: this.t("changes_are_local"),
            visible_for_permissions: [{actions: ["DELETE"], path: "/subsystems/ns/alerts/.*"}]
          },
        ],
      },
      configurations: [],
      messages: [],
    };
  },
  computed: {
    emailSelected() {
      let selected = false;
      for (let index in this.configurations) {
        if (this.configurations[index].mail) {
          selected = true;
          break;
        }
      }
      return selected;
    },
    rows: {
      get() {
        return this.$store.getters["networksecure/alerts/getAlerts"];
      },
      set() {},
    },
  },
  methods: {
    refresh(wait) {
      this.updating = true;
      this.$emit("refresh");
      setTimeout(() => {
        this.updating = false;
      }, 2000);
    },
    resetModal() {
      this.$refs.modal.hide();
    },
    openModal() {
      this.$refs.modal.show();
    },
    handleOkModal() {
      console.log("ok... reset values");
      this.getData();
      this.refresh();
      this.$refs.modal.hide();
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    handleSave() {
      if (this.emailSelected) {
        if (this.model.subject === "") {
          this.submitValidationText = this.t("subject_required");
          return this.$store.commit("setErrorAll", this.submitValidationText);
        }
        if (this.model.sender === "") {
          this.submitValidationText = this.t("sender_required");
          return this.$store.commit("setErrorAll", this.submitValidationText);
        }
        if (this.model.addresses.length === 0) {
          this.submitValidationText = this.t("one_email_required");
          return this.$store.commit("setErrorAll", this.submitValidationText);
        }
      }
      if (this.errors.items.length == 0) {
        console.log("messages:", this.messages);
        const result = {
          default_actions: this.configurations,
          mail_action_config: this.model,
          excluded_events: prepareSaveDefaultEventList(this.messages),
        };
        this.$store.dispatch("networksecure/alerts/changeDefaultEvent", result);
      } else {
        this.submitValidationText = this.errors.items[0].msg;
        return this.$store.commit("setErrorAll", this.submitValidationText);
      }
    },
    handleNewAlert() {
      this.$router.push({
        name: "Network Secure / General / General Management / Alerts / New",
      });
    },
    handleClose(tag) {
      this.errorMessage = undefined;
      let newValues = [...this.value];
      newValues.splice(this.value.indexOf(tag), 1);
      delete this.valuesType[tag.toLowerCase()];
      this.$emit("change", newValues);
    },
    handleToggle(check, name, type) {
      this.configurations[name][type] = check;
      // this.$emit('alerts', check, index, name)
    },
    // Table methods
    handleRemoveClick(event) {
      this.$store.dispatch("networksecure/alerts/deleteAlert", event.row.name);
    },
    handleEditClick(event) {
      this.$router.push({
        name: "Network Secure / General / General Management / Alerts / Edit",
        params: { id: event.row.name },
      });
    },
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
    },
    handleNodeClick(data) {
      console.log(data);
    },
    getData() {
      this.$store
        .dispatch("networksecure/alerts/getAllEvents")
        .then((events) => {
          this.$store
            .dispatch("networksecure/alerts/getAlertsData")
            .then((alerts) => {
              this.$store
                .dispatch("networksecure/alerts/getDefaultEvents")
                .then((defEvents) => {
                  const rows = this.$store.getters[
                    "networksecure/alerts/getAlerts"
                  ];
                  this.rows = rows;
                  const configurations = JSON.parse(
                    JSON.stringify(
                      this.$store.getters[
                        "networksecure/alerts/getDefaultActions"
                      ]
                    )
                  );
                  this.configurations = configurations;
                  const model = {
                    ...this.$store.getters["networksecure/alerts/getMailData"],
                  };
                  this.model = model;
                  const eventsSelected = this.$store.getters[
                    "networksecure/alerts/getExcluded"
                  ];
                  const dataEvents = JSON.parse(
                    JSON.stringify(
                      this.$store.getters["networksecure/alerts/getAllEvents"]
                    )
                  );
                  // Need to pass alert_event_type_list too?
                  this.messages = prepareEventDefaultList(
                    dataEvents,
                    eventsSelected,
                    []
                  );
                });
            });
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped lang="scss">
  .form-container {
    padding-left: 2em;
    min-width: 78%;
  }
  .alerts-header {
    margin-left: 1em;

    span {
      margin-left: 1em;
    }
  }
  .add-button-div {
    display: flex;
    justify-content: flex-end;
  }
  .add-button {
    cursor: pointer;
    fill: white;
    margin-top: 0px;
  }
  .refresh {
    cursor: pointer;
    margin-left: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
  }
</style>
