<template>
  <b-card class="page-head-actions">
    <div class="page-head-actions__stripe page-head-actions__stripe_1"></div>
    <div class="page-head-actions__stripe page-head-actions__stripe_2"></div>
    <div class="page-head-actions__stripe page-head-actions__stripe_3"></div>
    <div class="row">
      <div class="col-12 col-md-9">
        <div
          class="page-head-actions__input"
          v-if="editable"
        >
          <i :class="icon"></i>
          <input
            :disabled="readOnly"
            :style="{ cursor: readOnly ? 'not-allowed' : '' }"
            class="page-head-actions__input-field"
            :value="value"
            v-bind="$attrs"
            v-on="listeners"
            name="nameInput"
            @input="onInput"
            @focus="onFocus"
            @blur="onBlur"
            @keyup.enter="onEnterKeyUp"
            v-validate="validationRules"
          />
          <div class="el-form-item__error">{{ this.getError('nameInput') ? this.validationMessage : '' }}</div>
        </div>
        <h4
          class="page-head-actions__title"
          v-else
        >
          <i :class="icon"></i>
          <span>{{ title }}</span>
        </h4>
        <div
          class="page-head-actions__error"
          v-if="error"
        >{{ error }}</div>
      </div>
      <div class="col-12 col-md-3 page-head-actions__buttons">
        <slot name="buttons"></slot>
      </div>
      
    </div>
    <template v-slot:footer v-if="isRealId">
            <div class="hr-grey mb-2"/>
            <div @click="onRefresh" v-if="!updating" style="cursor: pointer;display: inline;">
                <i class="now-ui-icons arrows-1_refresh-69" />
                {{ t('Update now') }}
            </div>
            <div v-else>
                <i class="now-ui-icons loader_refresh spin" />
                {{ t('Updating...') }}
            </div>
    </template>
  </b-card>
</template>

<script>
export default {
  name:'page-head-actions',
  inheritAttrs: false,
  props:{
    title:{
      type: String,
      required: false
    },
    icon: {
      type: String,
      default: 'tim-icons icon-notes'
    },
    editable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    isRealId: {
      type: Boolean,
      default: false,
    },
    updating: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
   },
    validationRules: {
      type: String,
      default: '',
    },
    validationMessage: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      hasFocus: false,
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    onInput(event) {
      this.$emit('input', event.target.value);
    },
    onFocus(value) {
      this.hasFocus = true;
      this.$emit('focus', value);
    },
    onBlur(value) {
      this.hasFocus = false;
      this.$emit('blur', value);
    },
    onEnterKeyUp() {
      this.$emit('return');
    },
    onRefresh() {
      this.$emit('refresh');
    },
    validate() {
      return this.$validator.validate(this.nameInput);
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        focus: this.onFocus,
        blur: this.onBlur,
      };
    },
  }
};
</script>
