<template>
  <b-card no-body>
    <template v-slot:header>
      <b-container fuild style="max-width: 2560px">
        <b-row no-gutters align-h="between">
          <b-col md="12" lg="8" xl="9" class="pt-2">
            {{ t("title") }}
          </b-col>
          <b-col sm=""></b-col>
        </b-row>
      </b-container>
    </template>

    <b-card-body style="padding: 0px">
      <paginated-table
        class="host-and-process-control-table"
        ref="paginatedTable"
        :rows="rows"
        :columns="columns"
        :actions="actions"
        :itemsPerPage="query.limit"
        :updating="updating"
        :secsToRefresh="120"
        @item-suspend="handleSuspend"
        @item-stop="handleStop"
        @item-restart="handleRestart"
        @item-check-status="handleCheckStatus"
        @item-check-performance="handleCheckPerformance"
        @item-flush="handleFlush"
        @reload="reload"
      >
        <template v-slot:expand="props">
          <b-row align-h="end">
            <b-col cols="7">
              <b-row v-if="props.row.check_status_expanded">
                <b-col>
                  <b-form-group
                    v-for="item in props.row.check_status"
                    :key="item.label"
                    label-cols="5"
                    label-cols-lg="5"
                    :label="formatLabel(t(item.label))"
                    class="check-status-form-group"
                  >
                    <div class="col-form-content">
                      {{ item.content }}
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form
                v-if="props.row.check_performance_expanded"
                class="modal-form"
                :data-vv-scope="props.row.module"
              >
                <b-row>
                  <b-col>
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="10"
                      :label="t('Check Performance')"
                      label-for="input-default"
                    >
                      <n-switch
                        id="input-default"
                        key="item"
                        v-model="props.row.check_performance.enabled"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col> </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label-cols="4"
                      label-cols-lg="10"
                      :label="
                        t(
                          'Restart process if any of the followin conditions is met'
                        )
                      "
                      label-for="input-default"
                    >
                      <n-switch
                        id="input-default"
                        key="item"
                        v-model="props.row.check_performance.restart"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <fg-input
                      :label="t('Max CPU wait (second)')"
                      v-validate="'required|integer|min_value:0'"
                      type="text"
                      name="Max CPU wait"
                      :error="getError(`${props.row.module}.Max CPU wait`)"
                      v-model.number="props.row.check_performance.max_cpu_wait"
                    >
                    </fg-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <fg-input
                      :label="t('Max CPU usage in %')"
                      v-validate="'required|integer|min_value:0'"
                      type="text"
                      name="Max CPU usage"
                      :error="getError(`${props.row.module}.Max CPU usage`)"
                      v-model.number="props.row.check_performance.max_cpu"
                    >
                    </fg-input>
                  </b-col>
                  <b-col>
                    <fg-input
                      :label="t('Max Memory wait (seconds)')"
                      v-validate="'required|integer|min_value:0'"
                      type="text"
                      name="Max Memory wait"
                      :error="getError(`${props.row.module}.Max Memory wait`)"
                      v-model.number="
                        props.row.check_performance.max_memory_wait
                      "
                    >
                    </fg-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <fg-input
                      :label="t('Max Memory usage in MB')"
                      v-validate="'required|integer|min_value:0'"
                      type="text"
                      name="Max Memory usage"
                      :error="getError(`${props.row.module}.Max Memory usage`)"
                      v-model.number="props.row.check_performance.max_memory"
                    >
                    </fg-input>
                  </b-col>
                  <b-col>
                    <fg-input
                      :label="t('Sample rate (seconds)')"
                      v-validate="'required|integer|min_value:0'"
                      type="text"
                      name="Sample rate"
                      :error="getError(`${props.row.module}.Sample rate`)"
                      v-model.number="props.row.check_performance.sample_rate"
                    >
                    </fg-input>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <n-button
                      class="float-right"
                      type="primary"
                      size="md"
                      @click.native="handleCheckPerformanceOk(props)"
                    >
                      {{ t("accept") }}
                    </n-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-col>
          </b-row>
        </template>
      </paginated-table>
    </b-card-body>

    <template v-slot:footer>
      <div class="hr-grey mb-2" />
      <div
        @click="reload"
        v-if="!updating"
        style="cursor: pointer; display: inline"
      >
        <i class="now-ui-icons arrows-1_refresh-69" />
        {{ t("Update now") }}
      </div>
      <div v-else>
        <i class="now-ui-icons loader_refresh spin" />
        {{ t("Updating...") }}
      </div>
    </template>

  </b-card>
</template>

<script>
import _ from "lodash";

import { Switch } from "@/components";
import { PaginatedTable } from "@/components";
import {
  ArrowFormatter,
  YesNoIconFormatter,
} from "@/components/Tables/Formatters";

export default {
  name: "host-and-process-control",
  components: {
    PaginatedTable,
    [Switch.name]: Switch,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {
        module: "",
        check_performance: {
          enabled: false,
          max_cpu: 0,
          max_cpu_wait: 0,
          max_memory: 0,
          max_memory_wait: 0,
          module_id: {
            hostname: "",
            module_instance: "",
          },
          restart: false,
          sample_rate: 0,
        },
      },
      modal: {
        check_performance: {
          hostname: "",
          module: "",
          module_instance: "",
        },
      },
      updating: false,
      hosts_and_processes: [],
      query: {
        sort: "asc",
        limit: 10,
      },
      self_query: {},
      currentPage: 1,
      columns: [
        {
          prop: "module",
          label: this.t("Module"),
          minWidth: 175,
        },
        {
          prop: "module_group",
          label: this.t("Module Group"),
          minWidth: 150,
        },
        {
          prop: "status",
          label: this.t("Status"),
          minWidth: 170,
          formatter: YesNoIconFormatter,
        },
        {
          prop: "pid",
          label: this.t("PID"),
          minWidth: 100,
        },
        {
          prop: "cpu",
          label: this.t("CPU"),
          minWidth: 100,
          formatter: ArrowFormatter,
        },
      ],
      actions: {
        minWidth: 210,
        label: "Actions",
        fixed: "right",
        items: [
          {
            isSVGIcon: true,
            isIconOriginal: true,
            icon: "pause",
            iconClass: "svg-icon-pause",
            iconWidth: "27",
            iconHeight: "27",
            event: "item-suspend",
            action: this.t("suspend"),
            confirm: true,
            message:
              this.t('Are you sure you want to perform \'Suspend\' action?'),
            visibleIf: (row) => { 
              return (row["module_instance"].indexOf("ProcessMonitor") > -1)
                ? false
                : row["status"] === true;
            },
            visible_for_permissions: [
              { actions: ["POST"], path: "/subsystems/ns/module/action" },
            ],
          },
          {
            isSVGIcon: true,
            isIconOriginal: true,
            icon: "stop",
            iconClass: "svg-icon-stop",
            iconWidth: "27",
            iconHeight: "27",
            event: "item-stop",
            action: this.t("stop"),
            confirm: true,
            message:
              this.t('Are you sure you want to perform \'Stop\' action?'),
            visibleIf: (row) => { 
              return (row["module_instance"].indexOf("ProcessMonitor") > -1)
                ? false
                : row["status"] === true;
            },
            visible_for_permissions: [
              { actions: ["POST"], path: "/subsystems/ns/module/action" },
            ],
          },
          {
            isSVGIcon: true,
            isIconOriginal: true,
            icon: "ok",
            iconClass: "svg-icon-ok",
            iconWidth: "27",
            iconHeight: "27",
            event: "item-restart",
            action: this.t("restart"),
            confirm: true,
            message:
              this.t('Are you sure you want to perform \'Restart\' action?'),
            visibleIf: (row) => {
              return row['status'] === false && row['pid'] === 0
            },
            visible_for_permissions: [
              { actions: ["POST"], path: "/subsystems/ns/module/action" },
            ],
          },
          {
            isSVGIcon: true,
            isIconOriginal: true,
            icon: "points",
            iconClass: "svg-icon-points",
            iconWidth: "27",
            iconHeight: "27",
            event: "item-check-status",
            action: this.t("Check Status"),
            visibleIf: (row) => {
              return row["soap_port"] !== 0;
            },
            visible_for_permissions: [
              { actions: ["POST"], path: "/subsystems/ns/module/status" },
            ],
          },
          {
            isSVGIcon: true,
            isIconOriginal: true,
            icon: "tools",
            iconClass: "svg-icon-tools",
            iconWidth: "27",
            iconHeight: "27",
            event: "item-check-performance",
            action: this.t("Check Performance"),
            visible_for_permissions: [
              {
                actions: ["POST"],
                path: "/subsystems/ns/module/checkperformance",
              },
            ],
          },
          {
            isSVGIcon: true,
            isIconOriginal: true,
            icon: "download",
            iconClass: "svg-icon-download",
            iconWidth: "27",
            iconHeight: "27",
            event: "item-flush",
            action: this.t("Flush Log"),
            visibleIf: (row) => {
              return row["module"] === "WebFilter";
            },
            visible_for_permissions: [
              { actions: ["POST"], path: "/subsystems/ns/module/action" },
            ],
          },
        ],
      },
    };
  },
  mounted() {
    this.refresh(0);
  },
  computed: {
    rows() {
      let result = [];
      const modules = this.$store.getters[
        "networksecure/hosts_and_processes/modules"
      ](this.id);

      for (const property in modules) {
        result.push({
          module: property,
          module_group: modules[property].module_instance,
          status: modules[property].status,
          pid: modules[property].pid,
          cpu: modules[property].cpu,
          hostname: this.id,
          module_instance: modules[property].module_instance,
          soap_port: modules[property].soap_port,

          check_status: [],
          check_status_expanded: false,
          check_performance: {},
          check_performance_expanded: false,
        });
      }
      return result;
    },
    host_status() {
      let result = [];
      const host_status = this.$store.getters[
        "networksecure/hosts_and_processes/hostStatus"
      ];

      result = Object.keys(host_status).map((prop) => {
        return {
          label: prop,
          content: host_status[prop],
        };
      });

      return result;
    },
    host_and_process_status() {
      let result = [];
      const host_and_process_status = this.$store.getters[
        "networksecure/hosts_and_processes/hostAndProcessStatus"
      ];

      result = Object.keys(host_and_process_status).map((prop) => {
        return {
          label: prop,
          content: host_and_process_status[prop],
        };
      });

      return result;
    },
    checkPerformanceTitle() {
      let title = `${this.t("Check Performance")}`;

      if (this.modal.check_performance.hostname)
        title = `${title} - ${this.modal.check_performance.hostname}`;
      if (this.modal.check_performance.module)
        title = `${title} - ${this.modal.check_performance.module}`;
      if (this.modal.check_performance.module_instance)
        title = `${title} - ${this.modal.check_performance.module_instance}`;

      return title;
    },
  },
  methods: {
    formatLabel(label) {
      return `${label}:`;
    },
    handleAction(data, action) {
      const payload = {
        hostname: data.row.hostname,
        module_instance: data.row.module_instance,
        action: action,
      };

      this.$store.dispatch("networksecure/hosts_and_processes/executeHostAndProcessAction", payload)
        .then(res => {
          this.$store.commit('setSuccess', `${this.t(action)} ${this.t('action_successful')}`)
        })
        .catch((err) => {});
    },
    handleSuspend(data) {
      const action = "suspend";
      this.handleAction(data, action);
    },
    handleStop(data) {
      const action = "stop";
      this.handleAction(data, action);
    },
    handleRestart(data) {
      const action = "restart";
      this.handleAction(data, action);
    },
    handleFlush(data) {
      const action = "flush_logs";
      this.handleAction(data, action);
    },
    handleCheckStatus(data) {
      let row = data.row;
      const payload = {
        hostname: row.hostname,
        module_instance: row.module_instance,
      };

      const table = this.$refs.paginatedTable.$refs.myTable;

      if (row.check_performance_expanded) {
        row.check_performance_expanded = !row.check_performance_expanded;
        table.toggleRowExpansion(row, false);
      }

      if (!row.check_status_expanded) {
        this.$store
          .dispatch(
            "networksecure/hosts_and_processes/getHostAndProcessStatus",
            payload
          )
          .then((res) => {
            console.log("getHostAndProcessStatus:", res);
            let result = [];
            const host_and_process_status = res;

            result = Object.keys(host_and_process_status).map((prop) => {
              return {
                label: prop,
                content: host_and_process_status[prop],
              };
            });

            row.check_status = result;
            row.check_status_expanded = !row.check_status_expanded;

            table.toggleRowExpansion(row, row.check_status_expanded);
          });
      } else {
        row.check_status_expanded = !row.check_status_expanded;
        table.toggleRowExpansion(row, row.check_status_expanded);
      }
    },
    handleCheckPerformance(data) {
      let row = data.row;
      const payload = {
        hostname: data.row.hostname,
        module_instance: data.row.module_instance,
      };
      const table = this.$refs.paginatedTable.$refs.myTable;

      if (row.check_status_expanded) {
        row.check_status_expanded = !row.check_status_expanded;
        table.toggleRowExpansion(row, row.check_status_expanded);
      }

      if (!data.row.check_performance_expanded) {
        this.$store
          .dispatch(
            "networksecure/hosts_and_processes/getHostAndProcessPerformance",
            payload
          )
          .then((res) => {
            row.check_performance = res;
            row.check_performance_expanded = !data.row
              .check_performance_expanded;

            table.toggleRowExpansion(row, row.check_performance_expanded);
          });
      } else {
        row.check_performance_expanded = !data.row.check_performance_expanded;
        table.toggleRowExpansion(row, row.check_performance_expanded);
      }
    },
    async handleCheckPerformanceOk(data) {
      let isValidForm = false;

      isValidForm = await this.$validator.validateAll(data.row.module);
      if (isValidForm) {
        const payload = data.row.check_performance;
        this.$store
          .dispatch(
            "networksecure/hosts_and_processes/updateHostAndProcessPerformance",
            payload
          )
          .then((res) => {});
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    resetModal() {
      this.model.module = "";
      this.$validator.reset();
    },
    reload() {
      this.refresh();
    },
    refresh(wait) {
      this.updating = true;
      this.$store
        .dispatch("networksecure/status/getNetworkSecureHosts", {
          wait: wait,
        })
        .then((res) => {
          this.updating = false;
        })
        .catch((err) => {
          this.updating = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-form /deep/ input.form-control {
  color: black;
}
.bv-no-focus-ring {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>

<style lang="scss">
#modal-check-status div.bv-no-focus-ring,

.host-and-process-control-table {
  .el-table__expanded-cell {
    //background-color: transparent !important;

    .check-status-form-group {
      margin-bottom: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      overflow-wrap: break-word;

      .col-form-label,
      .col-form-content {
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: 1px solid black;
      }

      .col-form-content {
        height: 100%;
      }
    }
  }

  .el-table__expand-icon {
    display: none;
  }

  .table-actions {
    button,
    button:hover,
    button:active {
      background-color: transparent !important;
      background-image: none !important;
    }

    .svg-icon-pause path {
      fill: #f59800;
    }
    .svg-icon-ok path {
      fill: #8baa2a;
    }
    .svg-icon-stop path {
      fill: #ef4947;
    }
    .svg-icon-download path,
    .svg-icon-points path {
      fill: #0c5eaf;
    }
    .svg-icon-tools path {
      fill: #92d925;
    }
  }
}
</style>
