<template>
  <div>
    <page-title-card
      :page-title="t('title')"
      :page-description="t('description')"
    >
      <template #button>
        <n-button
          class="btn btn-round btn-block btn-primary btn-md"
          @click.native="handleStartBackup"
          :visible_for_permissions="[{actions: ['POST'],path: '/subsystems/ns/backups/start/Configuration'}]"
        >
          <i><svgicon class="icon" icon="icon-new" /></i>
          {{ t("New") }}
        </n-button>
      </template>
    </page-title-card>
    <b-card no-body>
      <b-card-body style="padding: 0px" id="backup-table">
        <paginated-table
          :rows="rows"
          :columns="columns"
          :actions="actions"
          :search="['timestamp']"
          v-model="search_field_value"
          :itemsPerPage="query.limit"
          :updating="updating"
          :serverpagination="search_pagination"
          @prev="handlePrevPage"
          @next="handleNextPage"
          @pagination="handlePagination"
          @item-remove="handleRemove"
          @item-details="handleShowDetails"
        />
      </b-card-body>

      <template v-slot:footer>
        <div class="hr-grey mb-2" />
        <div
          @click="reload"
          v-if="!updating"
          style="cursor: pointer; display: inline"
        >
          <i class="now-ui-icons arrows-1_refresh-69" />
          {{ t("Update now") }}
        </div>
        <div v-else>
          <i class="now-ui-icons loader_refresh spin" />
          {{ t("Updating...") }}
        </div>
      </template>

      <b-modal
        id="modal-backup-details"
        ref="modal"
        :title="t('Backup info')"
        @ok="resetModal"
        @show="resetModal"
        @hidden="resetModal"
        :ok-title="t('accept')"
        :cancel-title="t('cancel')"
      >
        <b-container fuild>
          <b-row no-gutters align-h="between">
            <label class="col-sm-6 col-lg-6 col-form-label"
            >{{ t("Backup Id") }}: {{ model.backup_id }}</label
            >
            <label class="col-sm-6 col-lg-6 col-form-label"
            >{{ t("Backup Type") }}: {{ model.backup_type }}</label
            >
          </b-row>
          <b-row no-gutters align-h="between">
            <b-form-textarea
              plaintext
              :value="model.content"
              rows="9"
            ></b-form-textarea>
          </b-row>
        </b-container>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import { PaginatedTable } from "@/components";
import _ from "lodash";
import { DateTime as objDateTime } from "luxon";
import PageTitleCard from "../../../components/AsmConfigurations/PageTitleCard";

export default {
  name: "network_secure_backups",
  components: {
    PaginatedTable,
    PageTitleCard
  },
  data() {
    return {
      model: {},
      updating: false,
      backups: new Map(),
      query: {
        sort: "asc",
        limit: 10,
      },
      self_query: {},
      currentPage: 1,
      columns: [
        {
          prop: "backup_id",
          label: this.t("backup"),
          minWidth: 200,
        },
        {
          prop: "backup_type",
          label: this.t("Type"),
          minWidth: 100,
        },
        {
          prop: "timestamp",
          label: this.t("Date"),
          minWidth: 100,
          formatter: (value, row) => {
            return this.stringToDate(value.timestamp);
          },
        },
        {
          prop: "time",
          label: this.t("Time"),
          minWidth: 100,
          formatter: (value, row) => {
            return this.stringToTime(value.timestamp);
          },
        },
      ],
      actions: {
        minWidth: 100,
        label: this.t("Actions"),
        fixed: "right",
        items: [
          {
            type: "warning",
            icon: "now-ui-icons business_badge",
            event: "item-details",
            action: this.t("Details"),
          },
          {
            type: "danger",
            size: "sm",
            icon: "now-ui-icons ui-1_simple-remove",
            event: "item-remove",
            action: this.t("Remove"),
            confirm: true,
            message: "Are you sure you want to delete the selected backup?",
            visible_for_permissions: [{actions: ["DELETE"], path: "/subsystems/ns/backups/start/Configuration"}]
          },
        ],
      },
    };
  },
  mounted() {
    this.refresh(0);
  },
  computed: {
    rows() {
      return Array.from(this.backups).sort((a, b) => {
        return a.backup_id.toUpperCase() > b.backup_id.toUpperCase() ? 1 : -1;
      });
    },
    search_field_value: {
      get() {
        let field_name = "timestamp";
        if (this.query && this.query.field_value)
          return { field: field_name, value: this.query.field_value };
        else return { field: field_name, value: "" };
      },
      set(newValue) {
        this.handleSearch(newValue);
      },
    },
    search_pagination: {
      get() {
        return {
          prev: !!this.query && !!this.query.prev_id,
          next: !!this.query && !!this.query.next_id,
          currentPage: this.currentPage,
        };
      },
    },
  },
  methods: {
    datetimeFromISO(string) {
      const datetime = objDateTime.fromISO(string);
      return datetime.isValid ? datetime : null;
    },
    stringToDate(string) {
      let datetime = this.datetimeFromISO(string);
      return datetime ? datetime.toFormat("yyyy-dd-MM") : "-";
    },
    stringToTime(string) {
      let datetime = this.datetimeFromISO(string);
      return datetime
        ? datetime.toLocaleString(objDateTime.TIME_24_WITH_SECONDS)
        : "-";
    },

    resetModal() {
      this.$validator.reset();
    },
    handlePrevPage() {
      if (this.query) {
        ["next_id", "next_name"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
        if (this.query.prev_id) {
          this.currentPage -= 1;
          this.refresh();
        }
      }
    },
    handleNextPage() {
      if (this.query) {
        ["prev_id", "prev_name"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
        if (this.query.next_id) {
          this.currentPage += 1;
          this.refresh();
        }
      }
    },
    handlePagination(value) {
      this.currentPage = 1;
      this.self_query.limit = value;
      ["next_id", "next_name", "prev_id", "prev_name"].forEach((item) => {
        if (this.self_query[item]) delete this.self_query[item];
      });
      this.reload();
    },
    handleSearch: _.debounce(function (value) {
      this.currentPage = 1;
      this.nextPage = 0;
      if (value) {
        this.query.field_operator = "sw";
        this.query.field_name = value.field;
        this.query.field_value = value.value;
      } else {
        ["field_operator", "field_name", "field_value"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
      }
      ["next_id", "next_name", "prev_id", "prev_name"].forEach((item) => {
        if (this.query[item]) delete this.query[item];
      });
      this.refresh();
    }, 500),
    reload() {
      this.refresh();
    },
    refresh(wait) {
      let _vm = this;
      this.updating = true;
      this.$store
        .dispatch("networksecure/backups/getNetworkSecureBackups", {
          wait: wait,
        })
        .then((res) => {
          _vm.backups = res.items;
          this.updating = false;
        })
        .catch((err) => {
          this.updating = false;
        });
    },
    handleRemove(data) {
      this.$store
        .dispatch(
          "networksecure/backups/removeNetworkBackup",
          data.row.backup_id
        )
        .then(() => {
          this.reload();
        });
    },
    handleShowDetails(data) {
      let _vm = this;
      this.$store
        .dispatch("networksecure/backups/getNetworkBackup", data.row.backup_id)
        .then((res) => {
          _vm.model = { ...{ content: res.items.join("\n") }, ...data.row };
          this.$refs.modal.show();
        });
    },
    handleStartBackup() {
      this.$store
        .dispatch("networksecure/backups/startNetworkBackup")
        .then(() => {
          this.$store.commit("setSuccess", this.t("backup_started"));
          this.reload();
        });
    },
  },
};
</script>
<style>
.form-control-plaintext {
  border: 1px solid gray;
  border-radius: 10px;
  padding: 10px;
}

#backup-table .card-footer {
  display: none;
}

#backup-table .card-header div:first-child {
  display: none;
}
</style>

