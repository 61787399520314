<template>
  <div>
    <b-card id="alert-page-creation" class="card-user" no-body>
      <div class="author">
        <div class="block block-one"></div>
        <div class="block block-three"></div>
        <div class="block block-four"></div>
      </div>
      <b-row>
        <fg-input
          id="alert_id"
          v-model="alert_id"
          v-validate="{ required: true, regex: /^[a-zA-Z0-9\-\._@]+$/ }"
          name="Alert ID"
          :error="getError('Alert ID')"
          class="no-border no-height form-control-lg alert-input"
          :placeholder="t('Alert ID')"
          addon-left-icon="now-ui-icons business_badge"
          autocapitalize="none"
          style="font-size: 1rem"
          :readonly="!isNewAlert"
        />
        <b-col md="4" lg="3" xl="2" class="ml-auto header-buttons">
          <n-button
            id="save-alert"
            @click.native="handleSubmit"
            type="primary"
            size="md"
            round
            block
            :visible_for_permissions="[{ actions: ['POST'], path: '/subsystems/ns/alerts/.*' },{ actions: ['PATCH'], path: '/subsystems/ns/alerts/.*' }]"
            >{{ t("Save") }}</n-button
          >
        </b-col>
      </b-row>
      <div class="hr-grey mb-2" />
      <b-row>
        <template v-if="!isNewAlert">
          <div @click="refresh" v-if="!updating" class="refresh">
            <i class="now-ui-icons arrows-1_refresh-69" /> {{ t("Update now") }}
          </div>
          <div v-else class="refresh">
            <i class="now-ui-icons loader_refresh spin" />
            {{ t("Updating...") }}
          </div>
        </template>
        <b-col md="4" lg="3" xl="2" class="ml-auto header-buttons">
          <n-button
            id="cancel-alert"
            @click.native="handleCancel"
            type="primary"
            size="md"
            round
            block
            >{{ t("Back") }}</n-button
          >
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h4 class="alerts-header">
        <svgicon icon="new-alert" width="24" height="24" />
        <span>{{ t("New Alert") }}</span>
      </h4>
      <div class="container-check">
        <b-row>
          <b-col md="12" lg="6" xl="6" class="pt-2 alert-actions">
            <h4>
              <span>{{ t("Alert actions") }}</span>
            </h4>
            <b-row>
              <b-col xl="6" lg="12" md="6">
                <b-row class="switch-alert-action">
                  <b-col md="8">
                    <div>{{ t("Record in System Log") }}</div>
                  </b-col>
                  <b-col md="4">
                    <n-switch
                      id="system_log"
                      :key="alert_id + 'system'"
                      v-model="actions.system_log"
                      @input="handleToggle($event, 'system_log')"
                      :visible_for_permissions="[{ actions: ['POST'], path: '/subsystems/ns/alerts/.*' },{ actions: ['PATCH'], path: '/subsystems/ns/alerts/.*' }]"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col xl="6" lg="12" md="6">
                <b-row class="switch-alert-action">
                  <b-col md="8">
                    <div>{{ t("Send SNMP Trap") }}</div>
                  </b-col>
                  <b-col md="4">
                    <n-switch
                      id="snmp"
                      :key="realId + 'snmp'"
                      v-model="actions.SNMP"
                      @input="handleToggle($event, 'SNMP')"
                      :visible_for_permissions="[{ actions: ['POST'], path: '/subsystems/ns/alerts/.*' },{ actions: ['PATCH'], path: '/subsystems/ns/alerts/.*' }]"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col xl="6" lg="12" md="6">
                <b-row class="switch-alert-action">
                  <b-col md="8">
                    <div>{{ t("Record in internal Log") }}</div>
                  </b-col>
                  <b-col md="4">
                    <n-switch
                      id="internal"
                      :key="realId + 'internal'"
                      v-model="actions.internal_log"
                      @input="handleToggle($event, 'internal_log')"
                      :visible_for_permissions="[{ actions: ['POST'], path: '/subsystems/ns/alerts/.*' },{ actions: ['PATCH'], path: '/subsystems/ns/alerts/.*' }]"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col xl="6" lg="12" md="6">
                <b-row class="switch-alert-action">
                  <b-col md="8">
                    <div>{{ t("Send via e-mail") }}</div>
                  </b-col>
                  <b-col md="4">
                    <n-switch
                      id="send-check"
                      :key="realId + 'email'"
                      v-model="actions.mail"
                      @input="handleToggle($event, 'mail')"
                      :visible_for_permissions="[{ actions: ['POST'], path: '/subsystems/ns/alerts/.*' },{ actions: ['PATCH'], path: '/subsystems/ns/alerts/.*' }]"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12" lg="6" xl="6">
            <div v-if="actions.mail">
              <mail-configuration
                id="mail-configuration"
                :model="model"
                :visible_for_permissions="[{ actions: ['PATCH','POST'], path: '/subsystems/ns/alerts/.*' }]"
              ></mail-configuration>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <message-configuration
      :messages="getEvents"
      :infoTitle="t('selected_events')"
      :selectionTitle="t('event_types')"
      :visible_for_permissions="[{ actions: ['POST'], path: '/subsystems/ns/alerts/.*' },{ actions: ['PATCH'], path: '/subsystems/ns/alerts/.*' }]"              
    />
  </div>
</template>
<script>
import Vue from "vue";
import { Switch } from "@/components";
import { Collapse, CollapseItem } from "element-ui";
import MessageConfiguration from "./components/MessageConfiguration";
import {
  prepareEventList,
  prepareSaveEventList,
  addTypeAlert,
} from "./alertUtils.js";
import MailConfiguration from "./components/MailConfiguration";

export default {
  name: "edit-alert",
  components: {
    MailConfiguration,
    [Switch.name]: Switch,
    "el-collapse": Collapse,
    "el-collapse-item": CollapseItem,
    "message-configuration": MessageConfiguration,
  },
  data() {
    return {
      updating: false,
      alert_id: "",
      submitValidationText: "",
      // switch
      model: {
        subject: "",
        sender: "",
        addresses: [],
      },
      actions: {
        SNMP: false,
        internal_log: false,
        mail: false,
        system_log: false,
      },
      events: [],
    };
  },
  props: {
    id: {
      default: null,
    },
  },
  computed: {
    getEvents() {
      return this.events;
    },
    isNewAlert() {
      return !!!this.id;
    },
    realId: {
      get() {
        if (this.isNewAlert) {
          return this.alert_id;
        } else {
          return this.id;
        }
      },
      set(newValue) {
        this.alert_id = newValue;
      },
    },
  },
  methods: {
    refresh(wait) {
      this.getData();
      this.updating = true;
      this.$emit("refresh");
      setTimeout(() => {
        this.updating = false;
      }, 2000);
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    handleSubmit() {
      console.log("handle submit....");
      if (this.realId) {
        if (this.checkActions()) {
          if (this.actions.mail) {
            if (this.model.subject === "") {
              this.submitValidationText = this.t("subject_required");
              return this.$store.commit(
                "setErrorAll",
                this.submitValidationText
              );
            }
            if (this.model.sender === "") {
              this.submitValidationText = this.t("sender_required");
              return this.$store.commit(
                "setErrorAll",
                this.submitValidationText
              );
            }
            if (this.model.addresses.length === 0) {
              this.submitValidationText = this.t("one_email_required");
              return this.$store.commit(
                "setErrorAll",
                this.submitValidationText
              );
            }
          }
          if (this.checkEvents()) {
            if (this.errors.items.length == 0) {
              // save it
              if (this.isNewAlert) {
                const result = {
                  alert_id: this.realId,
                  actions: this.actions,
                  mail_action_config: this.model,
                  alert_event_type_list: addTypeAlert(this.events),
                  events: prepareSaveEventList(this.events),
                };
                this.$store.dispatch(
                  "networksecure/alerts/createAlert",
                  result
                );
                return this.$router.push({
                  name:
                    "Network Secure / General / General Management / Alerts",
                });
              } else {
                const result = {
                  alert_id: this.realId,
                  actions: this.actions,
                  mail_action_config: this.model,
                  alert_event_type_list: addTypeAlert(this.events),
                  events: prepareSaveEventList(this.events),
                };
                this.$store.dispatch(
                  "networksecure/alerts/updateAlerts",
                  result
                );
                return this.$store.commit("setSuccess", this.t("save_success"));
              }
            }
            this.submitValidationText = this.errors.items[0].msg;
            return this.$store.commit("setErrorAll", this.submitValidationText);
          }
          this.submitValidationText = this.t("must_have_event");
          return this.$store.commit("setErrorAll", this.submitValidationText);
        }
        this.submitValidationText = this.t("must_have_action");
        return this.$store.commit("setErrorAll", this.submitValidationText);
      }
      this.submitValidationText = this.t("id_required");
      return this.$store.commit("setErrorAll", this.submitValidationText);
    },
    handleCancel() {
      this.$router.push({
        name: "Network Secure / General / General Management / Alerts",
      });
    },
    handleToggle(check, name) {
      this.actions[name] = check;
    },
    checkActions() {
      return (
        this.actions.mail ||
        this.actions.internal_log ||
        this.actions.system_log ||
        this.actions.SNMP
      );
    },
    checkEvents() {
      let selected = false;
      const source = this.events;
      for (let index in source) {
        if (source[index].selected) {
          return true;
        } else {
          for (let indexChild in source[index].children) {
            if (source[index].children[indexChild].selected) {
              selected = true;
              break;
            }
          }
        }
      }
      return selected;
    },
    getData() {
      if (this.isNewAlert) {
        this.$store
          .dispatch("networksecure/alerts/getAllEvents")
          .then((event) => {
            const dataEvents = event;
            this.events = prepareEventList(dataEvents, [], []);
          });
      } else {
        this.setId;
        this.$store
          .dispatch("networksecure/alerts/getAlertById", this.realId)
          .then((alert) => {
            this.actions = { ...alert.actions };
            this.model = { ...alert.mail_action_config };
            const eventsSelected = alert.events ? alert.events : [];
            const eventTypesSelected = alert.alert_event_type_list
              ? alert.alert_event_type_list
              : [];
            this.$store
              .dispatch("networksecure/alerts/getAllEvents")
              .then((event) => {
                const dataEvents = event;
                this.events = prepareEventList(
                  dataEvents,
                  eventsSelected,
                  eventTypesSelected
                );
              });
          });
      }
    },
  },
  watch: {
    id(newValue, oldValue) {
      if (newValue) {
        this.alert_id = newValue;
      }
    },
  },
  mounted() {
    // check configuration
    const alertId = this.$route.params.pathMatch;
    if (this.isNewAlert) {
      this.$store
        .dispatch("networksecure/alerts/getAllEvents")
        .then((event) => {
          const dataEvents = event;
          this.events = prepareEventList(dataEvents, [], []);
        });
    } else {
      this.alert_id = this.realId;
      this.$store
        .dispatch("networksecure/alerts/getAlertById", this.realId)
        .then((alert) => {
          this.actions = { ...alert.actions };
          this.model = { ...alert.mail_action_config };
          const eventsSelected = alert.events ? alert.events : [];
          const eventTypesSelected = alert.alert_event_type_list
            ? alert.alert_event_type_list
            : [];
          this.$store
            .dispatch("networksecure/alerts/getAllEvents")
            .then((event) => {
              const dataEvents = event;
              this.events = prepareEventList(
                dataEvents,
                eventsSelected,
                eventTypesSelected
              );
            });
        });
    }
  },
};
</script>

<style scoped lang="scss">
.container {
  margin-top: 3em;
}

.form-container {
  padding-left: 2em;
  min-width: 78%;
}

.collapse-item {
  margin-left: 10px;
}

.container-check {
  padding-bottom: 2em;
}

.alert-input {
  width: 50%;
  margin-left: 2em;
}

.switch-alert-action {
  display: flex;
  align-items: center;
}

.header-buttons {
  margin-right: 2em;
}

.alerts-header {
  span {
    margin-left: 1em;
  }
}

.alert-actions {
  margin-bottom: 1em;
}

.refresh {
  cursor: pointer;
  margin-left: 2em;
  margin-top: 1em;
}
</style>
