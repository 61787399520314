import { render, staticRenderFns } from "./MailConfiguration.vue?vue&type=template&id=0a029659&"
import script from "./MailConfiguration.vue?vue&type=script&lang=js&"
export * from "./MailConfiguration.vue?vue&type=script&lang=js&"
import style0 from "./MailConfiguration.vue?vue&type=style&index=0&id=0a029659&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports