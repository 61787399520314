export default {
  methods: {
    getSubtype(frequency) {
      let sub_type = null;
      const sub_types = ['months', 'weeks', 'days'];

      if (frequency) {
        sub_types.forEach((value) => {
          if (value in frequency) {
            sub_type = value;
          }
        });
      }

      return sub_type;
    },
    getSubtypeValue(sub_type, frequency) {
      let sub_type_value = null;

      if (sub_type && frequency) {
        if (frequency[sub_type]) {
          sub_type_value = frequency[sub_type].join(',');
        }
      }

      return sub_type_value;
    },
  }
};
