
<template>
  <b-row class="message-configuration">
    <b-col sm="6" xs="12">
      <b-card>
        <b-row>
              <b-col md="12" lg="12" xl="12">
                <h4>{{infoTitle}}</h4>
              </b-col>
              <b-col md="12" lg="12" xl="12">

            <el-collapse v-model="activeNames">
                <div v-for="(item, index) in messages" :key="index" >
                <el-collapse-item :title="item.label" :name="index" v-if="hasSelection(item)">
                  <div v-for="(child, indexChild) in item.children" :key="indexChild" >
                      <div class="collapse-item" v-if="child.selected">
                        <span class="tree-label-2">
                          <span class="element-code">{{child.code}}</span>
                          <span>{{child.label}}</span>
                        </span>
                      </div>
                  </div>
                </el-collapse-item>
              </div>
            </el-collapse>
            </b-col>
            </b-row>
      </b-card>
    </b-col>
    <b-col sm="6" xs="12">
      <b-card>
        <b-row>
              <b-col md="12" lg="12" xl="12">
                <h4>{{selectionTitle}} </h4>
              </b-col>
              <b-col md="12" lg="12" xl="12">
            <el-collapse v-model="activeNames">
              <div v-for="(item, index) in messages" :key="index + item.label" >
              <n-switch class="general-switch" role="general-switch" :key="item.label + 'switch'" v-model="messages[index].selected" @input="toggle($event, index)" :visible_for_permissions="visible_for_permissions"/>
                <el-collapse-item class="collapse-label" :name="index + item.label" v-if="item.children.length > 0">
                  <template slot="title">
                    <div class="title-check"> {{item.label}} </div>
                  </template>
                  <div v-for="(child, indexChild) in item.children" :key="indexChild + child.label" >
                      <div class="collapse-item">
                        <n-switch :key="child.label + indexChild" v-model="child.selected" @input="toggleChild($event, index)" :visible_for_permissions="visible_for_permissions"/>
                        <span class="tree-label">
                          <span class="element-code">{{child.code}}</span>
                          <span>{{child.label}}</span>
                        </span>
                      </div>
                  </div>
                </el-collapse-item>
            </div>
            </el-collapse>
              </b-col>
            </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import Vue from 'vue';
import { Switch } from '@/components';
import { Collapse, CollapseItem } from 'element-ui';

  export default {
    name: 'message-configuration',
    components: {
        [Switch.name]: Switch,
        'el-collapse': Collapse,
        'el-collapse-item': CollapseItem
    },
     data() {
        return {
          activeNames: [],
        }
    },
    props: {
      messages: { type: Array },
      selectionTitle: {type: String},
      infoTitle: {type: String},
      visible_for_permissions: { 
          type: Array, 
          default: function () { return [] }
      }
      /******  TYPE SAMPLE: *******/
      /* [
            {label: 'critical',
            selected: false,
            children: [{label: 'crit1', selected: false}] },
            {label: 'error',
            selected: false,
            children: [{label: 'error1', selected: false, }] },
            {label: 'warning',
            selected: false,
            children: [{label: 'warning', selected: false }] },
            {label: 'information',
            selected: false,
            children: [{label: 'inf1',  selected: false} , {label: 'inf2', selected: false}, {label: 'inf3',  selected: false}]}
          ]
      */
    },
    computed: {
      name() {
        return this.data
      }
    },
    methods: {
      toggle(check, index) {
        this.messages[index].selected = check;
        this.messages[index].children.map(element => element.selected = check)
      },
      toggleChild(check, index) {
        if (!check) this.messages[index].selected = false;
      },
      hasMessageSelected() {
         let selected = false
         for (let index in this.messages) {
            if (this.messages[index].selected) {
              return selected = true
            } else {
               let source = this.messages[index]
               for (let indexChild in source.children) {
                 if (source.children[indexChild].selected) {
                  return selected = true
                 }
               }
            }
         }
         return selected
      },
      hasSelection(source, index) {
        let selected = false
        if (source.selected) {
           return true
        } else {
           for (let index in source.children) {
             if (source.children[index].selected) {
               selected = true
               break;
             }
           }
        }
        return selected
      },
    },
  }
</script>

<style lang="scss">
  .message-configuration {
    .title-check {
      padding-left: 6em;
    }
    .general-switch {
      position: absolute;
      margin-top: 10px;
    }
    .tree-label {
      margin-left: 2.2em;
    }
    .tree-label-2 {
      margin-left: 1em;
    }
    .element-code {
      margin-right: 1em;
    }
  }
</style>
