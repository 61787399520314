<template>
  <div>
    <page-title-card
      :page-title="t('title')"
      :page-description="t('description')"
    >
      <template #button>
        <n-button
          @click.native="handleNew"
          type="primary"
          size="md"
          round
          block
          :visible_for_permissions='[{actions: ["POST"], path: "/subsystems/ns/scheduled-tasks"}]'
        >
          <i slot="label"><svgicon class="icon" icon="icon-new" /></i>
          {{t('New')}}
        </n-button>
      </template>
    </page-title-card>
    <b-card no-body>
      <b-card-body style="padding: 0px;">
        <paginated-table :rows="scheduled_tasks"
                         :columns="columns"
                         :actions="actions"
                         :search="['name']"
                         @item-edit="handleEdit"
                         @item-remove="handleRemove" />
      </b-card-body>
      <template v-slot:footer>
        <div class="hr-grey mb-2"/>
        <div @click="refresh" v-if="!updating" style="cursor: pointer;display: inline;">
          <i class="now-ui-icons arrows-1_refresh-69" />
          {{ t('Update now') }}
        </div>
        <div v-else>
          <i class="now-ui-icons loader_refresh spin"/>
          {{ t('Updating...') }}
        </div>
      </template>

      <b-modal
        id="modal-new-scheduled-task"
        ref="modal-new-scheduled-task"
        :title="t('New Scheduled task')"
        @ok.prevent="handleOk"
      >
        <b-form @submit.prevent="">
          <fg-input v-model="model.task_name"
                    v-validate="{required: true, regex: /^[a-zA-Z0-9\-\._@]+$/}"
                    name="Task name"
                    :error="getError('Task name')"
                    class="no-border no-height form-control-lg scheduled-tasks-modal-input"
                    :placeholder="t('Task name')"
                    addon-left-icon="now-ui-icons business_badge"
                    autocapitalize="none"
                    style="font-size:1rem;" />

          <b-form-group
            :label="this.t('Select module') + ':'"
          >
            <el-select
              class="select-primary"
              :placeholder="this.t('Module')"
              v-model="model.module_name"
              style="width:100%;"
              v-validate="'required'"
              name="Task Module"
              :error="getError('Task Module')"
            >
              <el-option
                v-for="option in module_options"
                class="select-primary"
                :value="option.value"
                :label="option.label"
                :key="option.value"
              >
              </el-option>
            </el-select>
            <div
              class="text-danger mt-1"
              style="font-size: 13px;"
              v-show="getError('Task Module')"
            >
              {{ getError('Task Module') }}
            </div>
          </b-form-group>
          <b-form-group
            :label="this.t('Select operation') + ':'"
          >
            <el-select
              class="select-primary"
              :placeholder="this.t('Function')"
              v-model="model.module_function"
              style="width:100%;"
              v-validate="'required'"
              name="Task Function"
              :error="getError('Task Function')"
            >
              <el-option
                v-for="option in operationFunctionOptions"
                class="select-primary"
                :value="option.value"
                :label="t(option.label)"
                :key="option.value"
              >
              </el-option>
            </el-select>
            <div
              class="text-danger mt-1"
              style="font-size: 13px;"
              v-show="getError('Task Function')"
            >
              {{ getError('Task Function') }}
            </div>
          </b-form-group>
        </b-form>
      </b-modal>

    </b-card>
  </div>
</template>

<script>
  import { Select, Option } from 'element-ui';
  import { PaginatedTable } from '@/components';
  import Tags from '@/components/Tables/Formatters/Tags';
  import ScheduledTasksMixin from '@/mixins/networksecure/ScheduledTasks/Mixin';
  import PageTitleCard from "../../../../../components/AsmConfigurations/PageTitleCard";

  export default {
    name: 'scheduled-tasks',
    mixins: [ScheduledTasksMixin],
    components: {
      [Option.name]: Option,
      [Select.name]: Select,
      PaginatedTable,
      PageTitleCard
    },
    data() {
      return {
        model: {
          task_name: '',
          module_name: '',
          module_function: '',
        },
        updating: false,
        query: {
          sort: 'asc',
          limit: 10
        },
        self_query: {},
        columns: [
          {
            prop: 'name',
            label: this.t('Task name'),
            minWidth: 185
          },
          {
            prop: 'module',
            label: this.t('Module'),
            minWidth: 185
          },
          {
            prop: 'function',
            label: this.t('Operation'),
            minWidth: 120
          },
          {
            prop: 'triggers',
            label: this.t('Frequency'),
            minWidth: 200,
            formatter: Tags,
            align: 'center'
          }
        ],
        actions: {
          minWidth: 100,
          label: this.t('Actions'),
          fixed: 'right',
          items: [
            {
              type: 'warning',
              icon: 'now-ui-icons business_badge',
              event: 'item-edit',
              action: this.t('Edit')
            },
            {
              type: 'danger',
              size: 'sm',
              icon: 'now-ui-icons ui-1_simple-remove',
              event: 'item-remove',
              action: this.t('Remove'),
              confirm: true,
              message: this.t('Are you sure you want to delete this scheduled task?'),
              visible_for_permissions: [{actions: ["DELETE"], path: "/subsystems/ns/scheduled-tasks"}]
            },
          ]
        }
      }
    },
    computed: {
      module_options() {
        return this.$store.getters["networksecure/scheduled_tasks/scheduledTaskModules"]
      },
      scheduled_tasks() {
        let items = [];
        if (this.$store.getters["networksecure/scheduled_tasks/scheduledTasks"].items) {
          this.$store.getters["networksecure/scheduled_tasks/scheduledTasks"].items.forEach((el, key) => {
           (!this.$store.getters['settings/isIOT'] || el.operation.module === "CentralManager") &&
            items.push({
              name: el.name,
              module: el.operation.module,
              function: el.operation.function,
              triggers: {
                frequency: this.formatFrequency(el.triggers.frequency),
                intervals: this.formatIntervals(el.triggers.intervals)
              },
            });
          });
        }
        return items;
      },
      operationFunctionOptions() {
        let data = this.$store.getters["networksecure/scheduled_tasks/scheduledTaskFunctions"]
        if(data[this.model.module_name] && this.model.module_name){
          return data[this.model.module_name]
        }
        else{
          return []
        }
      },
    },
    mounted() {
      this.$store.dispatch("networksecure/scheduled_tasks/getScheduledTaskFunctions")
      this.refresh(0);
    },
    watch:{
      'model.module_name':function(newValue, oldValue){
        if(oldValue){
          this.model.module_function = this.operationFunctionOptions[0].value
        }
      }
    },
    methods: {
      getError(fieldName) {
        return this.errors.first(fieldName);
      },
      async handleOk() {
        let isValidForm = await this.$validator.validateAll();
        if (isValidForm) {
          this.$router.push({
            name: 'Network Secure / General / General Management / Scheduled Tasks / Edit',
            params: {
              edit: false,
              id: this.model.task_name,
              module: this.model.module_name,
              function: this.model.module_function
            },
          });
        }
      },
      handleNew() {
        this.$refs['modal-new-scheduled-task'].show();
      },
      handleEdit(data) {
        this.$router.push({
          name: 'Network Secure / General / General Management / Scheduled Tasks / Edit',
          params: {
            edit: true,
            id: data.row.name,
            module: data.row.module,
            visible_for_permissions: [{actions: ["POST", "PUT"], path: "/subsystems/ns/scheduled-tasks"}]
          },
        });
      },
      handleRemove(data) {
        const payload = {
          name: data.row.name,
          module: data.row.module
        };

        this.$store.dispatch('networksecure/scheduled_tasks/removeScheduledTask', payload)
          .then(res => {
            this.$store.dispatch('networksecure/scheduled_tasks/getScheduledTasks', {wait: true, query: this.query});
          });
      },
      refresh(wait) {
        let _vm = this
        this.updating = true;
        this.$store.dispatch('networksecure/scheduled_tasks/getScheduledTasks', { wait: wait, query: this.query})
          .then(res => {
            _vm.query = res.query ? res.query : {}
            _vm.self_query = res.self ? res.self : {}
            if (this.changingPage) {
              this.currentPage += this.nextPage
            }
            this.updating = false;
          })
          .catch(err => {
            this.updating = false;
          });
      },

      formatFrequency(frequency) {
        let result = {
          title: ''
        };

        if (frequency) {
          if(
            /weekly|monthly/.test(frequency.type)
            && frequency.days
          ){
            if(frequency.days.length > 1)
            {
              result.title = `${frequency.days.length} ` + this.t('times per') + ` ` + this.t(frequency.type.slice(0,-2));
            }
            else{
              result.title = this.t('Once per') + ` ` + this.t(frequency.type.slice(0,-2));
            }
          }
          else if(
            /every/.test(frequency.type)
            && frequency.period
          ){
            const periiod = (frequency.time > 1) ? frequency.period : frequency.period.slice(0, -1)
            const time = (frequency.time === 1) ? '' : frequency.time
            result.title = this.t('Every') + ` ${time} ${periiod}`
          }
        }

        return result;
      },
      formatIntervals(intervals) {
        let result = {
          first: '',
          other: null
        };

        if (intervals && intervals.length) {
          result.first = `${intervals[0].begin} - ${intervals[0].end}`
          if(intervals.length > 1){
            result.other = `and ${intervals.length-1} more`
          }
        }

        return result;
      },
    }
  }
</script>

<style lang="scss">
  .scheduled-tasks-modal-input{
    input{
      color: #333;
      &:focus{
        color: #333;
      }
    }
    margin-bottom: 35px;
  }
</style>
