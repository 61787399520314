<template>
  <div class="mail-config-card">
    <b-row>
      <b-col md="12" lg="12" xl="12">
        <h4>{{t('Mail configuration')}}</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" lg="12" xl="12">
        <b-form class="form-container" @submit.prevent>
          <fg-input
            id='subject'
            class="mail-input"
            :label="t('Subject:')"
            v-model="model.subject"
            :placeholder="t('Mail Subject')"
            name="subject"
            :disabled="!permission_granted"
          ></fg-input>
          <fg-input
            id='sender'
            class="mail-input"
            :label="t('Sender:')"
            name="sender"
            v-model="model.sender"
            :placeholder="t('Mail sender')"
            type="email"
            v-validate="modelValidations.email"
            :error="getError('sender')"
            :disabled="!permission_granted"
          ></fg-input>
          <div class="mail-input">
            <label for="tags-pills">{{this.t('Addresses')}}:</label>
            <b-form-tags
              id='address'
              input-id="tags-pills"
              separator=","
              v-model="model.addresses"
              tag-variant="primary"
              type="email"
              :placeholder="t('Mail address')"
              class="mb-2"
              :tag-validator="emailValidator"
              remove-on-delete
              :disabled="!permission_granted"
            ></b-form-tags>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import permissions from '@/mixins/permissions'
  export default {
    name: 'mail-configuration',
    mixins: [permissions],
    data() {
      return {
        modelValidations: {
          email: {
            email: true
          }
        },
      }
    },
    props: {
      model: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    methods: {
      emailValidator(tag) {
        const re = RegExp(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        return re.test(tag.toLowerCase());
      },
      getError(fieldName) {
        return this.errors.first(fieldName);
      },
    }
  }
</script>

<style lang="scss">
.mail-config-card {
  margin-left: 1em;
}

.mail-input {
  display: contents;
}

.mail-input > label {
  margin-top: 10px;
  padding-right: 10px;
}

.mail-input > label:first-child {
  margin-top: 10px;
  padding-right: 10px;
}

.mail-input {
  .b-form-tags {
    ul {
      .badge-primary {
        background-color: #848484;
        color: white;
      }
    }
  } 
}

</style>