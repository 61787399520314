<template>
    <b-card  >
        <slot name="header">
            <h5 slot="header" class="title">
                <svgicon class="icon" icon="server-configuration" width="16" height="16" />
                <span style="padding-left:1em">{{t('Server Configuration')}}</span>
            </h5>
        </slot>        

        <form>
        <b-container fuild style="width: 100%; padding: 0px;" class="form-group">
            <b-row  align-h="center" >
                <b-col xl="5" lg="6">
                    <fg-input :visible_for_permissions="visible_for_permissions"
                        v-model="server.name"                       
                        v-validate="{ required: true, regex: /^([^:]){1,15}$/ }"
                        name="Server Name"
                        :error="getError('Server Name')"
                        :label="t('Server Name')"                                                
                        autocapitalize="yes"
                        :readonly="!newServer">                        
                    </fg-input>
                </b-col>

                <b-col xl="5" lg="6" class="mb-2">
                    <b-form-group label-cols="3" label-cols-lg="5" :label="t('Status')" label-for="input-status">
                        <n-switch v-model="server.status" id="input-status" :visible_for_permissions="visible_for_permissions"/>
                    </b-form-group>     
                </b-col>
            </b-row>
            <b-row  align-h="center">
                <b-col xl="5" lg="6">
                    <fg-input :visible_for_permissions="visible_for_permissions"
                        v-model="server.location"                        
                        :label="t('Server IP address or domain')"
                        name="IP address or Domain"                        
                        v-validate="'required|ip_or_fqdn'"
                        :error="getError('IP address or Domain')">
                    </fg-input>
                </b-col>
                <b-col xl="5" lg="6" >                    
                    <fg-input v-if="cluster_type.indexOf('Proxy') >= 0"
                        :visible_for_permissions="visible_for_permissions"
                        v-model="server.user"                         
                        :label="t('User')"
                        name="User"                        
                        v-validate="{ required: false, regex: /^[a-zA-Z0-9-_\s]+$/ }"
                        :error="getError('User')">
                    </fg-input>                   
                </b-col>                
            </b-row>
            <b-row  align-h="center">
                <b-col xl="5" lg="6">
                    <fg-input :visible_for_permissions="visible_for_permissions"
                        v-model="server.port"                         
                        :label="t('Listening port')"
                        name="Listening Port"                        
                        v-validate="'required|between:1,65535|numeric'"
                        :error="getError('Listening Port')"
                        @keypress='onKeyPress'
                        type="number">
                    </fg-input>
                </b-col> 
                <b-col xl="5" lg="6"> 
                    <fg-input v-if="cluster_type.indexOf('Proxy') >= 0"
                        :visible_for_permissions="visible_for_permissions"
                        v-model="server.password"                         
                        :label="t('Password')"
                        name="Password"  
                        type="password"                      
                        v-validate="{ required: false }"
                        :error="getError('Password')">
                    </fg-input>                     
                </b-col>                              
            </b-row>
            <b-row align-h="center"  >
                <b-col xl="5" lg="6">
                    <div class="form-group has-label"><label>{{ t('Mode') }}</label></div>
                    <el-select :disabled="!permission_granted"
                            v-model="server.mode"                         
                            class="select-primary"  
                            :placeholder="t('Mode')"
                            value="Primary">
                            <el-option v-for="option in $options.serverModes"
                                class="select-primary"
                                :value="option.key"
                                :label="t(option.value)"
                                :key="option.key">
                            </el-option>
                    </el-select>
                </b-col>
                <b-col xl="5" lg="6">                    
                </b-col>                
            </b-row>             
        </b-container>
        </form>
    </b-card>
</template>

<script>
import { Select, Option } from 'element-ui';
import { Switch } from '@/components';
import permissions from '@/mixins/permissions'

export default {
    name: 'server-form',
    mixins: [permissions],
    serverModes : [ {"key" : "primary", "value" : "Primary"}, 
                    {"key" : "secondary", "value" : "Secondary"}
                ],
    props: {            
            value: { 
              type: Object,  
            },
            cluster_name: {
                type : String,
            },
            cluster_type: {
                type : String,
            } 
        }, 
    data() {
        return {
            server: null,
            newServer: true
        }
    },           
    components: {
        [Option.name]: Option,
        [Select.name]: Select,
        [Switch.name]: Switch
    },
    methods: {
        onKeyPress(evt) {
            const event = evt || window.event; 
            const charCode = event.which || event.keyCode;

            if (charCode < 48 || charCode > 57) {
                event.preventDefault();
            } else {
                return true;
            }
        },        
        getError(fieldName) {                  
            return this.errors.first(fieldName);
        },
        async save(){        
            let isValid = await this.$validator.validateAll();
            if (isValid){                         
                this.$emit('input', this.server)
                setTimeout(() => {
                    this.$validator.reset();
                }, 100)
            }
        }
    },   
    watch: {
        value(newValue, oldValue) {                 
            if ( newValue ){
                this.server = newValue;
                if ( this.server.name )
                    this.newServer = false
            }
        },
    },
    mounted () {      
        if ( !!this.value ){
            this.server = this.value;        
            if ( this.server.name)
                this.newServer = false
        }
    },
    created() {
        this.server = {
            name        : "",
            type	    : "",
            status      : true,
            location	: "",
            mode	    : "primary",
            port	    : "",
            user        : "",
            password    : ""
        }
    },
    computed:{
           
    },
}

</script>
