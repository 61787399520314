<template>
  <router-view :key="$route.path"></router-view>
</template>

<script>
  export default {
    name: "scheduled-tasks-router",
  }
</script>

<style scoped>

</style>
