<template>
  <div>
    <page-title-card
      :page-title="t('title')"
      :page-description="t('description')"
    >
      <template #button>
        <n-button
          type="primary"
          size="md"
          round
          block
          v-b-modal.modal-new
          :visible_for_permissions='[{actions: ["POST"], path: "/subsystems/ns/external-servers"}]'
        >
          <i><svgicon class="icon" icon="icon-new"/></i>
          {{ t('New') }}
        </n-button>
      </template>
    </page-title-card>
    <b-card no-body>
      <b-card-body style="padding: 0px;">
        <nested-paginated-table :rows='clusters'
                                :columns='realColumns'
                                :nestedColumns='realNestedColumns'
                                :actions="actions"
                                :updating="updating"
                                :nestedActions="nestedActions"
                                :search="['cluster_name']"
                                :serverpagination="null"
                                @editServer="handleEditServer" @newServer="handleNewServer" @removeCluster="handleRemoveCluster" @removeServer="handleRemoveServer"/>
      </b-card-body>

      <template v-slot:footer>
        <div class="hr-grey mb-2"/>
        <div @click="refresh" v-if="!updating" style="cursor: pointer;display: inline;">
          <i class="now-ui-icons arrows-1_refresh-69" />
          {{ t('Update now') }}
        </div>
        <div v-else>
          <i class="now-ui-icons loader_refresh spin"/>
          {{ t('Updating...') }}
        </div>
      </template>

      <b-modal id="modal-new" ref="modal" :title="t('New Cluster')" @ok="handleOkNewCluster" @show="resetNewCluster" @hidden="resetNewCluster" :ok-title="t('accept')" :cancel-title="t('cancel')">
        <form class="authpages" @submit.prevent="handleNewCluster">
          <b-row >
            <b-col >
              <el-select class="select-primary"
                         style="width: 100%;"
                         @change="onClusterTypeChange"
                         v-validate="{ required: true }"
                         :placeholder="clusterTypePlaceholder"
                         v-model="model.cluster_type"
                         :disabled="isClusterSelectDisabled"
              >
                <el-option v-for="option in validClusterTypes"
                           class="select-primary"
                           :value="option.key"
                           :label="option.value"
                           :key="option.key"
                >
                </el-option>
              </el-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col >
              <fg-input v-model="model.cluster_name"
                        v-validate="{ required: true, regex: /^([a-zA-Z0-9\_]){1,15}$/, notexists: true}"
                        name="Cluster Name"
                        :error="getError('Cluster Name')"
                        class="no-border no-height form-control-lg"
                        :placeholder="t('Cluster Name')"
                        addon-left-icon="now-ui-icons business_badge"
                        autocapitalize="none"
                        style="font-size:1rem;"
                        :readonly="defaultClusters.includes(model.cluster_type)"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col xl="12" class="mb-2">
              <fg-input v-if="model.cluster_type==='SNMP'" v-model="model.cluster_community"
                        v-validate="{ community: true, regex: /^[a-zA-Z0-9-_\s]{1,10}$/, required:true }"
                        name="Cluster Community"
                        :error="getError('Cluster Community')"
                        class="no-border no-height form-control-lg"
                        :placeholder="t('Cluster Community')"
                        addon-left-icon="now-ui-icons business_badge"
                        autocapitalize="none"
                        style="font-size:1rem;" />
            </b-col>
          </b-row>
        </form>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import clusters_list from './clusters.json'
import { NestedPaginatedTable } from '@/components';
import { Select, Option} from 'element-ui';
import { YesNoListFormatter } from '@/components/Tables/Formatters'
import PageTitleCard from "../../../../components/AsmConfigurations/PageTitleCard";

export default {
    name: 'extsrvs',
    clusterTypes: clusters_list,
    components: {
        NestedPaginatedTable,
        PageTitleCard,
        [Select.name]: Select,
        [Option.name]: Option,
    },
    data() {
        return {
            model: {
                cluster_name: '',
                cluster_type: '',
                cluster_community: ''
            },
            defaultClusters: ['DNS', 'SNMP', 'Proxy'],
            updating: false,
            external_servers: new Map(),
            columns: [
                {
                    prop: 'cluster_name',
                    label: this.$t("extsrvs.NAME"),
                    minWidth: 130
                },
                {
                    prop: 'servers',
                    type : 'expand',
                    minWidth: 50,
                },
                {
                    prop: 'cluster_type',
                    label: this.$t('extsrvs.TYPE'),
                    minWidth: 130,
                },
                {
                    prop: 'cluster_status',
                    label: this.$t('extsrvs.STATUS'),
                    minWidth: 130,
                },
                {
                    prop: 'cluster_address',
                    label: this.$t('extsrvs.ADDRESS'),
                    minWidth: 130,
                },
                {
                    prop: 'cluster_mode',
                    label: this.$t('extsrvs.MODE'),
                    minWidth: 130,
                },
            ],
            actions: {
                minWidth: 100,
                label: this.$t('extsrvs.Actions'),
                fixed: 'right',
                items: [
                    {   type: 'danger', size: 'sm', icon: 'now-ui-icons ui-1_simple-remove', event: 'removeCluster', action: this.t('Remove'),
                        confirm: false, visible_for_permissions: [{actions: ["DELETE"], path: "/subsystems/ns/external-servers.*"}] },
                    {   type: 'primary', icon: 'now-ui-icons ui-1_simple-add', event: 'newServer', action: this.t('New'), visible_for_permissions: [{actions: ["POST"], path: "/subsystems/ns/external-servers.*"}]},
                ]
            },
            nestedColumns: [
                {
                    prop: 'server_name',
                    label: this.$t('extsrvs.NAME'),
                    minWidth: 150
                },
                {
                    prop: 'server_type',
                    label: this.$t('extsrvs.TYPE'),
                    minWidth: 130,
                },
                {
                    prop: 'server_status',
                    label: this.$t('extsrvs.STATUS'),
                    minWidth: 130,
                    formatter: YesNoListFormatter
                },
                {
                    prop: 'server_address',
                    label: this.$t('extsrvs.ADDRESS'),
                    minWidth: 130,
                },
                {
                    prop: 'server_mode',
                    label: this.$t('extsrvs.MODE'),
                    minWidth: 130,
                },
            ],
            nestedActions: {
                minWidth: 100,
                label: this.$t('extsrvs.Actions'),
                fixed: 'right',
                items: [
                    {type: 'warning', icon: 'now-ui-icons business_badge', event: 'editServer', action: this.t('Edit')},
                    {type: 'danger', size: 'sm', icon: 'now-ui-icons ui-1_simple-remove', event: 'removeServer', action: this.t('Remove'),
                                confirm: false, visible_for_permissions: [{actions: ["DELETE"], path: "/subsystems/ns/external-servers.*"}]},
                ]
            }
        }
    },
    mounted() {
        this.refresh(0)
    },
    computed: {
        layout() {
            return this.$store.getters["settings/layout"];
        },
        clusters(){
            let ret = [];
            let vm = this
            this.external_servers.forEach( (value, key) => {
                let servers = [];
                if( value.servers ){
                    value.servers.forEach( (svalue,key) => {
                        let item = {
                            server_name: svalue.name,
                            server_type: value.type + " " + this.$t('Server'),
                            server_status: [{ name: "", state : svalue.status }],
                            server_address: svalue.location,
                            server_mode: this.t(svalue.mode),
                            cluster_name : value.name,
                            cluster_type : value.type
                        }
                        servers.push(item)
                    })
                }
                let item = {
                    cluster_name: value.name,
                    cluster_type: value.type + " " + this.$t('Cluster'),
                    cluster_status: "-",
                    cluster_address: "-",
                    cluster_mode: "-",
                    servers: servers
                }
                ret.push(item)
            })
            let arRet = Array.from(ret).sort( (a,b) => {
                return a.cluster_name.toUpperCase() > b.cluster_name.toUpperCase() ? 1: -1
            })
            return arRet;
        },
        realColumns: {
            get(){
                if (this.layout == 'desktop'){
                    return this.columns
                }
                else if ( this.layout == 'tablet'){
                    return [ this.columns[0], this.columns[1]]
                }
                else{
                    return [ this.columns[0], this.columns[1]]
                }
            }
        },
        realNestedColumns: {
            get(){
                if (this.layout == 'desktop'){
                    return this.nestedColumns
                }
                else if ( this.layout == 'tablet'){
                    return [ this.nestedColumns[0]]
                }
                else{
                    return [ this.nestedColumns[0]]
                }
            }
        },
        validClusterTypes: function(){
            let clusterTypes = [];
            clusters_list.forEach(clusterType => {
              if (this.defaultClusters.includes(clusterType.key)) {
                if(!this.external_servers.get(`${clusterType.key}Default`)) clusterTypes.push(clusterType);
              }
            });
            return clusterTypes;
        },
        isClusterSelectDisabled () {
          return !this.validClusterTypes.length;
        },
        clusterTypePlaceholder () {
          if (!this.validClusterTypes.length) {
            return this.t('cluster_type_no_more_options');
          }
          return this.t('cluster_type');
        }
    },
    methods: {
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        resetNewCluster() {
            this.model.cluster_name = ''
            this.model.cluster_type = ''
            this.model.cluster_community = ''
            this.$validator.reset()
        },
        onClusterTypeChange(){
            this.model.cluster_name = "";
            if(this.defaultClusters.includes(this.model.cluster_type))
                this.model.cluster_name = this.model.cluster_type + 'Default';
        },
        handleOkNewCluster( bvModalEvt ) {
            bvModalEvt.preventDefault()
            this.handleNewCluster()
        },
        async handleNewCluster() {
            let isValidForm = await this.$validator.validateAll();
            if (!isValidForm)
                return
            let cluster = {
                name : this.model.cluster_name,
                type : this.model.cluster_type,
                community : this.model.cluster_community,
                default : (this.defaultClusters.includes(this.model.cluster_type)),
                //servers : new Map()
            }
            this.$store.dispatch('networksecure/externalservers/createCluster', cluster ).then( res => {
                this.$store.commit("setSuccess", this.$t("Cluster") + " " + cluster.name + " " + this.$t("successfully created"))
                this.external_servers = res;
            })
            .catch( err => {this.refresh(0); } )

            this.$nextTick(() => {
                this.$refs.modal.hide()
            })
        },
        handleEditServer(data){
            this.$router.push( {path: '/ns/General/GeneralManagement/ExternalServers/edit/' + encodeURIComponent(data.row.cluster_name) + "/" + encodeURIComponent(data.row.cluster_type) + "/" + encodeURIComponent(data.row.server_name)})
        },
        handleNewServer(data){
            this.$router.push( {path: '/ns/General/GeneralManagement/ExternalServers/new/' + encodeURIComponent(data.row.cluster_name) + "/" + encodeURIComponent(data.row.cluster_type)} )
        },
        handleRemoveServer(data){
            if(data.row.cluster_name === "DNSDefault" && this.external_servers.get("DNSDefault").servers.size <= 1 ){
                this.$bvModal.msgBoxConfirm(this.$t('This server cannot be deleted'), {
                    okTitle: this.$t('accept'),
                    cancelTitle: this.$t('cancel')
                })
                .then(confirmed => {
                })
                .catch(() => {
                })
            } else {
                this.$bvModal.msgBoxConfirm(this.$t('want_delete_server'), {
                    okTitle: this.$t('accept'),
                    cancelTitle: this.$t('cancel')
                })
                .then(confirmed => {
                    if(confirmed) {
                        this.$store.dispatch('networksecure/externalservers/deleteServer', {cluster_name : data.row.cluster_name, server_name : data.row.server_name})
                        .then( res => {this.external_servers = res
                            this.$store.commit("setSuccess", this.$t("Server") + " " + data.row.server_name + " " + this.$t("successfully removed"))
                        })
                    }
                })
                .catch(() => {
                })
            }
        },
        handleRemoveCluster(data){
            if(data.row.cluster_name === "DNSDefault" ){
                this.$bvModal.msgBoxConfirm(this.$t('cannot_delete_cluster'), {
                    okTitle: this.$t('accept'),
                    cancelTitle: this.$t('cancel')
                })
                .then(confirmed => {
                })
                .catch(() => {
                })
            } else {
                this.$bvModal.msgBoxConfirm(this.$t('want_delete_cluster'), {
                    okTitle: this.$t('accept'),
                    cancelTitle: this.$t('cancel')
                })
                .then(confirmed => {
                    if(confirmed) {
                        this.$store.dispatch('networksecure/externalservers/deleteCluster', data.row.cluster_name).then( res => {
                        this.$store.commit("setSuccess", this.$t("Cluster") + " " + data.row.cluster_name + " " + this.$t("successfully removed"))
                        this.external_servers = res
                    })
                    .catch( err => this.refresh(0) )
                    }
                })
                .catch(() => {
                })
            }
        },
        refresh(wait){
            let _vm = this
            this.updating = true;
            this.$store.dispatch('networksecure/externalservers/getExternalServers')
            .then( res => {
                _vm.external_servers = res.items
                this.updating = false
            })
            .catch( err => {
                this.updating = false
            });
        }
    },
    created() {
        this.$validator.extend(
            'notexists',{
                getMessage: field =>  field + ' ' + this.$t('already exists') + '.',
                validate: (value) => {
                    if (this.external_servers.get(value) ) return false;
                    return true;
                }
            });
        this.$validator.extend(
            'community',{
                getMessage: field => this.$t('Please enter a community name, for example public'),
                validate: (value) => {
                    if (value === "" ) return false;
                    return true;
                }
            });
    }

}
</script>

<style>


</style>
