<template>
  <b-form>
    <b-container fuild style="max-width: 2560px" class="p-0">
      <b-row>
        <b-col class="row-eq-height">
          <page-head-actions :title="title">
            <template slot="buttons">
              <n-button
                variant="primary"
                class="btn-round main-btn"
                type="primary"
                @click.native="save"
                :visible_for_permissions='[{actions: ["POST"], path: "/subsystems/ns/scheduled-tasks"}, {actions: ["PUT"], path: "/subsystems/ns/scheduled-tasks"}]'
              >
                {{ t("Save") }}
              </n-button>
              <b-button
                variant="primary"
                class="btn-round main-btn"
                @click="cancel"
              >
                {{ t("Cancel") }}
              </b-button>
            </template>
          </page-head-actions>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="4" class="row-eq-height col-xxl-4">
          <b-card>
            <h5 class="title">
              <i class="mr-2"><svgicon class="icon" icon="icon-operation" /></i>
              {{ t("Operation") }}
            </h5>
            <div class="fields-wrapper mb-1">
              <label class="d-block">{{ t("Select module") }}:</label>
              <el-select
                disabled
                class="select-primary"
                size="large"
                :placeholder="this.t('Module')"
                v-model="model.operation.module"
                style="margin-top: -10px; width: 100%"
              >
                <el-option
                  v-for="option in operationModules"
                  class="select-primary"
                  :value="option.value"
                  :label="option.label"
                  :key="option.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="fields-wrapper mb-5">
              <label>{{ t("Select operation") }}:</label>
              <el-select
                :disabled="!permission_granted"
                class="select-primary"
                size="large"
                :placeholder="this.t('Operation')"
                v-model="model.operation.function"
                style="margin-top: -10px; width: 100%"
              >
                <el-option
                  v-for="option in operationFunctionOptions"
                  class="select-primary"
                  :value="option.value"
                  :label="t(option.label)"
                  :key="option.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="fields-wrapper">
              <label>{{ t("Time between retries") }}:</label>
              <div class="d-flex fields-wrapper">
                <fg-input
                  :value="model.retry.time"
                  id="retry-time"
                  class="mr-2"
                  name="Retry Time"
                  v-validate="validateRetryTime"
                  :error="getError('Retry Time')"
                  @input="handleTimeEnter"
                  :visible_for_permissions="[{actions: ['POST', 'PUT'], path: '/subsystems/ns/scheduled-tasks'}]"
                >
                </fg-input>
                <el-select
                  :disabled="!permission_granted"
                  class="select-primary"
                  size="large"
                  :placeholder="this.t('Period')"
                  v-model="model.retry.period"
                  style="margin-top: -10px"
                >
                  <el-option
                    v-for="option in retryPeriodOptions"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </b-card>
        </b-col>

        <b-col cols="12" md="8" class="row-eq-height col-xxl-8">
          <b-card class="card-user">
            <h5 class="title">
              <i class="mr-2"><svgicon class="icon" icon="icon-triggers" /></i>
              {{ t("Triggers") }}
            </h5>
            <div class="d-flex flex-wrap">
              <div class="fields-wrapper margin-r-sm-80 mb-5">
                <div>
                  <label>{{ t("Perform this task") }}:</label>
                </div>
                <div class="fields-wrapper">
                  <el-select
                    :disabled="!permission_granted"
                    class="select-primary"
                    size="large"
                    :placeholder="this.t('Frequency Type')"
                    v-model="model.frequency.type"
                    style="margin-top: -10px; width: 100%"
                  >
                    <el-option
                      v-for="option in frequencyTypeOptions"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.value"
                    >
                    </el-option>
                  </el-select>

                  <div class="d-flex mt-3">
                    <array-item-picker
                      v-show="
                        model.frequency.type === 'monthly' ||
                        model.frequency.type === 'weekly'
                      "
                      v-model="frequencySubValues"
                      name="Frequency Days"
                      v-validate="'required_frequency_days'"
                      @change="updateFrequencyArray($event)"
                      :visible_for_permissions="[{actions: ['POST', 'PUT'], path: '/subsystems/ns/scheduled-tasks'}]"
                    >
                    </array-item-picker>
                    <div class="d-flex" v-if="model.frequency.type === 'every'">
                      <fg-input
                        :value="model.frequency.time"
                        class="mr-2"
                        id="frequency-value"
                        name="Frequency Time"
                        v-validate="validateFrequencyTime"
                        :error="getError('Frequency Time')"
                        @input="handleFrequencyTimeEnter"
                      >
                      </fg-input>
                      <el-select
                        :disabled="!permission_granted"
                        class="select-primary"
                        size="large"
                        :placeholder="this.t('Period')"
                        v-model="model.frequency.period"
                        style="margin-top: -10px"
                      >
                        <el-option
                          v-for="option in frequencyPeriodOptions"
                          class="select-primary"
                          :value="option.value"
                          :label="option.label"
                          :key="option.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div
                    class="text-danger invalid-feedback error-label mb-2"
                    v-if="getError('Frequency Days')"
                  >
                    {{ getError("Frequency Days") }}
                  </div>
                  <legend
                    v-show="model.frequency.type === 'monthly'"
                    class="reminder"
                  >
                    {{
                      t(
                        "Remember that 29th, 30th and 31th don't fit into each month"
                      )
                    }}.
                  </legend>
                </div>
              </div>

              <div class="fields-wrapper">
                <label>{{ t("New Range") }}:</label>
                <div class="d-flex flex-wrap">
                  <div class="fields-wrapper mb-3">
                    <b-form-group
                      label-cols="3"
                      :label="this.t('Start')"
                      label-for="interval-begin"
                    >
                      <fg-input
                        id="interval-begin"
                        v-model="model.interval.begin"
                        name="Begin"
                        v-validate=""
                        :error="getError('Begin')"
                        :visible_for_permissions="[{actions: ['POST', 'PUT'], path: '/subsystems/ns/scheduled-tasks'}]"
                      >
                        <datetime
                          type="time"
                          v-model="model.interval.begin"
                          :minute-step="5"
                          input-class="form-control"
                          class="theme-orange"
                          :phrases="{
                            ok: this.t('accept'),
                            cancel: this.t('cancel'),
                          }"
                          use12-hour
                          :disabled="!permission_granted"
                        >
                        </datetime>
                      </fg-input>
                    </b-form-group>
                    <b-form-group
                      label-cols="3"
                      :label="this.t('End')"
                      label-for="interval-end"
                    >
                      <fg-input id="interval-end" name="End" :visible_for_permissions="[{actions: ['POST', 'PUT'], path: '/subsystems/ns/scheduled-tasks'}]">
                        <datetime
                          type="time"
                          name="End"
                          v-model="model.interval.end"
                          v-validate="'greater'"
                          :minute-step="5"
                          input-class="form-control"
                          class="theme-orange"
                          :phrases="{
                            ok: this.t('accept'),
                            cancel: this.t('cancel'),
                          }"
                          use12-hour
                          :disabled="!permission_granted"
                        >
                        </datetime>
                        <div
                          class="text-danger invalid-feedback error-label"
                          v-if="getError('End')"
                        >
                          {{ getError("End") }}
                        </div>
                      </fg-input>
                    </b-form-group>
                    <n-button
                      type="primary"
                      class="small-btn mt-3"
                      size="md"
                      round
                      block
                      :disabled="is_add_btn_disabled"
                      @click.native="addInterval"
                      :visible_for_permissions="[{actions: ['POST', 'PUT'], path: '/subsystems/ns/scheduled-tasks'}]"
                    >
                      {{ t("Add") }}
                    </n-button>
                  </div>
                  <div class="fields-wrapper">
                    <label>{{ t("Time Ranges") }}:</label>
                    <b-form-select
                      v-model="model.selected_intervals"
                      :options="interval_options"
                      multiple
                      :select-size="4"
                      name="Intervals"
                      v-validate.continues="'required_intervals'"
                      :disabled="!permission_granted"
                    >
                    </b-form-select>
                    <div
                      class="text-danger invalid-feedback error-label"
                      v-if="getError('Intervals')"
                    >
                      {{ getError("Intervals") }}
                    </div>
                    <n-button
                      type="primary"
                      class="small-btn mt-3"
                      size="md"
                      round
                      block
                      :disabled="is_remove_btn_disabled"
                      @click.native="removeInterval"
                      :visible_for_permissions="[{actions: ['POST', 'PUT'], path: '/subsystems/ns/scheduled-tasks'}]"
                    >
                      {{ t("Remove") }}
                    </n-button>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-form>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import { Datetime } from "vue-datetime";
import { Select, Option } from "element-ui";
import PageHeadActions from "@/components/PageHeadActions";
import ArrayItemPicker from "@/components/ArrayItemPicker";
import ScheduledTasksMixin from "@/mixins/networksecure/ScheduledTasks/Mixin";
import permissions from '@/mixins/permissions'

export default {
  name: "scheduled-tasks-edit",
  mixins: [ScheduledTasksMixin, permissions],
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    PageHeadActions,
    ArrayItemPicker,
    datetime: Datetime,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    edit: {
      type: Boolean,
      default: true,
    },
    module: {
      type: String,
      required: true,
    },
    function: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      model: {
        task_name: "",

        // operation
        operation: {
          module: "",
          function: "",
        },

        // frequency
        frequency: {
          type: "monthly",
          days: [],
          period: "minutes",
          time: "",
        },

        // intervals
        interval: {
          begin: "",
          end: "",
        },
        intervals: [],
        selected_intervals: [],

        // retry
        retry: {
          period: "seconds",
          time: null,
        },
      },

      validation: {
        time: {
          required: true,
          regex: /^[0-9]*$/,
        },
      },

      weekDays: [
        { label: "monday", active: false },
        { label: "tuesday", active: false },
        { label: "wednesday", active: false },
        { label: "thursday", active: false },
        { label: "friday", active: false },
        { label: "saturday", active: false },
        { label: "sunday", active: false },
      ],
      monthDays: [
        ...Array.from(new Array(31).keys()).map(
          (item, index) => (item = { label: `${index + 1}`, active: false })
        ),
      ],
    };
  },
  computed: {
    operationModules() {
      return this.$store.getters[
        "networksecure/scheduled_tasks/scheduledTaskModules"
      ];
    },
    validateRetryTime() {
      return {
        ...this.validation.time,
        min_value: 0,
      };
    },
    validateFrequencyTime() {
      return {
        ...this.validation.time,
      };
    },
    operationFunctionOptions() {
      let data = this.$store.getters[
        "networksecure/scheduled_tasks/scheduledTaskFunctions"
      ];
      if (data[this.model.operation.module] && this.model.operation.module) {
        return data[this.model.operation.module];
      } else {
        return [];
      }
    },
    frequencyTypeOptions() {
      return [
        { value: "monthly", label: this.t("Monthly") },
        { value: "weekly", label: this.t("Weekly") },
        { value: "every", label: this.t("Every") },
      ];
    },
    frequencyPeriodOptions() {
      return [
        { value: "minutes", label: this.t("Minute") },
        { value: "hours", label: this.t("Hour") },
        { value: "days", label: this.t("Day") },
        { value: "weeks", label: this.t("Week") },
      ];
    },
    frequencySubValues() {
      switch (this.model.frequency.type) {
        case "monthly":
          return this.monthDays;
        case "weekly":
          return this.weekDays;
        default:
          return [];
      }
    },
    retryPeriodOptions() {
      return [
        { value: "seconds", label: this.t("Seconds") },
        { value: "minutes", label: this.t("Minutes") },
        { value: "hours", label: this.t("Hours") },
      ];
    },
    interval_options() {
      return this.model.intervals.map((el, key) => {
        return {
          value: key,
          text: `${el.begin} - ${el.end}`,
        };
      });
    },
    title() {
      return `${this.model.operation.module
        .split(/(?=[A-Z][a-z]{2,})/)
        .join(" ")} - ${this.model.task_name}`;
    },
    is_add_btn_disabled() {
      return !(this.model.interval.begin && this.model.interval.end);
    },
    is_remove_btn_disabled() {
      return !this.model.selected_intervals.length;
    },
  },
  watch: {
    "model.operation.module": function (newValue, oldValue) {
      if (oldValue) {
        this.model.operation.function = this.operationFunctionOptions[0].value;
      }
    },
  },
  mounted() {
    this.$store.dispatch(
      "networksecure/scheduled_tasks/getScheduledTaskFunctions"
    );
    if (!this.edit) {
      this.model.task_name = this.id;

      this.model.operation.module = this.module;
      this.model.operation.function = this.function;
    } else {
      const payload = {
        name: this.id,
        module: this.module,
      };
      this.$store
        .dispatch("networksecure/scheduled_tasks/getScheduledTask", payload)
        .then((res) => {
          this.model.task_name = res.name;

          this.model.operation.module = res.operation.module;
          this.model.operation.function = res.operation.function;

          this.model.frequency.type = res.triggers.frequency.type;
          if (res.triggers.frequency.days) {
            this.initDays(
              res.triggers.frequency.days,
              res.triggers.frequency.type
            );
          }
          this.model.frequency.period =
            res.triggers.frequency.period ||
            this.frequencyPeriodOptions[0].value;
          this.model.frequency.time = res.triggers.frequency.time || "";

          this.model.intervals = res.triggers.intervals;

          this.model.retry.period = res.triggers.retry.period;
          this.model.retry.time = res.triggers.retry.time;
        })
        .catch((err) => {
          // console.log(err);
          this.$router.push({
            name:
              "Network Secure / General / General Management / Scheduled Tasks",
          });
        });
    }
  },
  created() {
    let self = this;
    this.$validator.extend("greater", {
      getMessage(field, val) {
        return self.t("The final hour must be greater than the first one");
      },
      validate(value, field) {
        if (self.model.interval.begin && self.model.interval.end) {
          let begin = moment(self.model.interval.begin).unix();
          let end = moment(self.model.interval.end).unix();

          return end > begin;
        }
        return true;
      },
    });
    this.$validator.extend("required_intervals", {
      getMessage(field) {
        return self.t("The") + " " + field + " " + self.t("field is required");
      },
      validate(value, field) {
        return self.model.intervals.length > 0;
      },
    });
    this.$validator.extend("required_frequency_days", {
      getMessage(field) {
        return self.t("The") + " " + field + " " + self.t("field is required");
      },
      validate(value, field) {
        switch (self.model.frequency.type) {
          case "monthly": {
            return self.monthDays.filter((el) => el.active).length > 0;
          }
          case "weekly": {
            return self.weekDays.filter((el) => el.active).length > 0;
          }
          default: {
            return true;
          }
        }
      },
    });
  },
  methods: {
    async save() {
      let isValid = await this.$validator.validateAll();

      if (isValid) {
        const payload = {
          name: this.model.task_name,
          module: this.model.operation.module,
          operation: {
            module: this.model.operation.module,
            function: this.model.operation.function,
          },
          triggers: {
            frequency: this.getFrequencyPayload(),
            intervals: this.model.intervals,
            retry: this.model.retry,
          },
        };

        if (!this.edit) {
          this.$store
            .dispatch(
              "networksecure/scheduled_tasks/createScheduledTask",
              payload
            )
            .then((res) => {
              this.$store.commit("setSuccess", this.t("save_success"));
            });
        } else {
          this.$store
            .dispatch(
              "networksecure/scheduled_tasks/updateScheduledTask",
              payload
            )
            .then((res) => {
              this.$store.commit("setSuccess", this.t("save_success"));
            });
        }
      }
    },
    async addInterval() {
      let isValidBegin = await this.$validator.validate("Begin");
      let isValidEnd = await this.$validator.validate("End");

      if (isValidBegin && isValidEnd) {
        let interval = {
          begin:
            new Date(this.model.interval.begin)
              .toTimeString()
              .split(" ")[0]
              .split(":")[0] +
            ":" +
            new Date(this.model.interval.begin)
              .toTimeString()
              .split(" ")[0]
              .split(":")[1],
          end:
            new Date(this.model.interval.end)
              .toTimeString()
              .split(" ")[0]
              .split(":")[0] +
            ":" +
            new Date(this.model.interval.end)
              .toTimeString()
              .split(" ")[0]
              .split(":")[1],
        };

        if (interval.begin && interval.end) {
          this.model.intervals.push(interval);

          this.model.interval.begin = "";
          this.model.interval.end = "";
        }
      }
    },
    removeInterval() {
      if (this.model.selected_intervals) {
        this.model.intervals = this.model.intervals.filter((item, key) => {
          return !this.model.selected_intervals.includes(key);
        });
        this.model.selected_intervals = [];
      }
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    getFrequencyPayload() {
      let payload = {
        type: this.model.frequency.type,
      };
      switch (this.model.frequency.type) {
        case "every": {
          payload.period = this.model.frequency.period;
          payload.time = Number(this.model.frequency.time);
          break;
        }
        case "monthly": {
          payload.days = this.monthDays.reduce((accumulator, el) => {
            if (el.active) {
              accumulator.push(Number.parseInt(el.label));
            }
            return accumulator;
          }, []);
          break;
        }
        case "weekly": {
          payload.days = this.weekDays.reduce((accumulator, el) => {
            if (el.active) {
              accumulator.push(el.label.toLowerCase());
            }
            return accumulator;
          }, []);
          break;
        }
      }
      return payload;
    },
    updateFrequencyArray({ data, index }) {
      switch (this.model.frequency.type) {
        case "monthly": {
          this.$set(this.monthDays, index, data[index]);
          break;
        }
        case "weekly": {
          this.$set(this.weekDays, index, data[index]);
          break;
        }
      }
    },
    initDays(days, type) {
      this.model.frequency.days = days;
      let key = "";

      switch (type) {
        case "monthly": {
          key = "monthDays";
          break;
        }
        case "weekly": {
          key = "weekDays";
          break;
        }
      }
      if (key) {
        days.forEach((element) => {
          this[key].forEach((day, index) => {
            if (element == day.label) {
              day.active = true;
              this.$set(this[key], index, day);
            }
          });
        });
      }
    },
    handleTimeEnter(value) {
      if (this.validation.time.regex.test(value)) {
        this.model.retry.time = Number(value);
      }
    },
    handleFrequencyTimeEnter(value) {
      if (this.validation.time.regex.test(value)) {
        this.model.frequency.time = value;
      }
    },
    cancel() {
      this.$router.push({
        name: "Network Secure / General / General Management / Scheduled Tasks",
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.fields-wrapper {
  width: 100%;
  max-width: 340px;
}
.small-btn {
  max-width: 140px;
}
.main-btn {
  max-width: 260px;
}
.reminder {
  font-size: 12px;
  color: #cb4b16;
}
.margin-r-sm-80 {
  @media (min-width: 576px) {
    margin-right: 80px;
  }
}
.error-label {
  white-space: normal;
  line-height: normal;
  display: block;
}
</style>
