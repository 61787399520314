<template>
  <div>
    <page-title-card
      :page-title="t('title')"
      :page-description="t('description')"
    />
    <b-card class="card-user" no-body>
      <div class="author">
        <div class="block block-one"></div>
        <div class="block block-three"></div>
        <div class="block block-four"></div>
      </div>
      <b-card-body style="margin-top: -30px; min-height: 75px">
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col cols="12">
                <h5 class="title">
                  <i class="mr-2"><svgicon class="icon" icon="item-status" /></i
                  >{{ t("Services Status") }}
                </h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="text-center">
                <b-container fluid style="padding: 0px">
                  <b-row no-gutters>
                    <b-col
                      :sm="6"
                      :md="6"
                      :xl="6"
                      v-for="(value, name, index) in service_status"
                      :key="name"
                    >
                      <b-container fluid style="padding: 0px">
                        <b-row no-gutters>
                          <b-col
                            cols="8"
                            class="form-group has-label pt-2"
                            align-self="center"
                          >
                            <h5
                              class="mx-auto my-auto"
                              style="white-space: nowrap"
                            >
                              {{ t(name) }}
                            </h5>
                          </b-col>
                          <b-col cols="3" align-self="center">
                            <el-select :class="service_status[name].global ? 'select-primary' : ''"
                              :value="service_status[name].status"
                              @input="handleChangeServiceStatus($event, name, index)"
                              :disabled="!service_status[name].global"
                            >
                              <el-option
                                v-for="option in statusOptions"
                                class="select-primary"
                                :key="option"
                                :label="t(option)"
                                :value="option">
                              </el-option>
                            </el-select>
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card class="card-user">
      <template v-slot:header>
        <b-container fuild style="max-width: 2560px">
          <b-row no-gutters align-h="between">
            <b-col md="12" lg="12" xl="12" class="pt-2">
              {{ t("statusTitle") }}
            </b-col>
          </b-row>
        </b-container>
      </template>

      <b-card-body style="padding: 0px" class="host-table">
        <paginated-table
          :rows="rows"
          :columns="columns"
          :actions="actions"
          :search="['hostname']"
          v-model="search_field_value"
          :itemsPerPage="query.limit"
          :updating="updating"
          :serverpagination="search_pagination"
          @prev="handlePrevPage"
          @next="handleNextPage"
          @pagination="HandlePagination"
          @item-change-location="handleChangeLocation"
          @item-details="handleShowDetails"
        />
      </b-card-body>

      <template v-slot:footer>
        <div class="hr-grey mb-2" />
        <div
          @click="reload"
          v-if="!updating"
          style="cursor: pointer; display: inline"
        >
          <i class="now-ui-icons arrows-1_refresh-69" />
          {{ t("Update now") }}
        </div>
        <div v-else>
          <i class="now-ui-icons loader_refresh spin" />
          {{ t("Updating...") }}
        </div>
      </template>
    </b-card>
    <b-modal
      id="modal-change-location"
      ref="modal"
      :title="model.hostname"
      @ok="handleOk"
      @show="resetModal"
      @hidden="resetModal"
      :ok-title="t('accept')"
      :cancel-title="t('cancel')"
    >
      <form class="authpages" @submit.prevent="handleSubmit">
        <fg-input
          v-model="model.physical_location"
          v-validate="{ required: true, regex: /^[a-zA-Z0-9\-\._@]+$/ }"
          name="Location"
          :error="getError('Location')"
          class="no-border no-height form-control-lg"
          :placeholder="t('Location')"
          addon-left-icon="now-ui-icons business_badge"
          autocapitalize="none"
          style="font-size: 1rem"
        />
      </form>
    </b-modal>
  </div>
</template>

<script>
import { PaginatedTable, Switch } from "@/components";
import {
  ArrowFormatter,
  YesNoIconFormatter,
} from "@/components/Tables/Formatters";
import { mapActions, mapGetters }   from 'vuex';
import PageTitleCard from "../../../components/AsmConfigurations/PageTitleCard";
import _ from "lodash";

export default {
  name: "network_secure_status",
  components: {
    PaginatedTable,
    PageTitleCard,
    [Switch.name]: Switch,
  },
  data() {
    return {
      model: {
        host: "",
        location: "",
      },
      updating: false,
      query: {
        sort: "asc",
        limit: 10,
      },
      self_query: {},
      currentPage: 1,
      columns: [
        {
          prop: "hostname",
          label: this.t("Host"),
          minWidth: 175,
          default: "not available",
        },
        {
          prop: "physical_location",
          label: this.t("Location"),
          minWidth: 175,
          default: "not available",
        },
        {
          prop: "internal_ip",
          label: this.t("IP Address"),
          minWidth: 175,
          default: "not available",
        },
        {
          prop: "status",
          label: this.t("Status"),
          minWidth: 175,
          formatter: YesNoIconFormatter,
          default: "not available",
        },
        {
          prop: "used_cpu",
          label: this.t("CPU"),
          minWidth: 175,
          formatter: ArrowFormatter,
          postfix: "%",
          default: "not available",
        },
        {
          prop: "used_memory",
          label: this.t("Used Mem"),
          minWidth: 175,
          formatter: ArrowFormatter,
          postfix: "%",
          default: "not available",
        },
        {
          prop: "used_hd",
          label: this.t("Used Disk"),
          minWidth: 175,
          formatter: ArrowFormatter,
          postfix: "%",
          default: "not available",
        },
      ],
      actions: {
        minWidth: 100,
        label: this.t("Actions"),
        fixed: "right",
        items: [
          {
            type: "warning",
            icon: "now-ui-icons business_badge",
            event: "item-details",
            action: this.t("Details"),
            visible_for_permissions: [
              { actions: ["GET"], path: "/subsystems/ns/general-status" },
            ],
          },
          {
            type: "danger",
            size: "sm",
            icon: "now-ui-icons location_pin",
            event: "item-change-location",
            action: this.t("Change Location"),
            visible_for_permissions: [
              { actions: ["PUT"], path: "/subsystems/ns/host-locations.*" },
            ],
          },
        ],
      },
      statusOptions: ['on', 'off', 'monitor']
    };
  },
  async mounted() {
    await this.refresh(0);
    this.loadServiceStatus();
  },
  computed: {
		...mapGetters('ispServices/services', ['global_configurations']),
    rows() {
      return Array.from(this.hosts).sort((a, b) => {
        return a.hostname.toUpperCase() > b.hostname.toUpperCase() ? 1 : -1;
      });
    },
    search_field_value: {
      get() {
        if (this.query && this.query.field_value)
          return { field: "hostname", value: this.query.field_value };
        else return { field: "hostname", value: "" };
      },
      set(newValue) {
        this.handleSearch(newValue);
      },
    },
    search_pagination: {
      get() {
        return {
          prev: !!this.query && !!this.query.prev_id,
          next: !!this.query && !!this.query.next_id,
          currentPage: this.currentPage,
        };
      },
    },
    service_status() {
      let data = {};
      let store_service_status = this.$store.getters[
        "networksecure/status/serviceStatus"
      ];

      if (this.$store.getters["networksecure/status/networkSecureSnifferMode"]) {
        store_service_status = store_service_status.filter(
          service => service.subsystem_service_id != 'Firewall'
            && service.subsystem_service_id != 'Activation'
            && service.subsystem_service_id != 'AntiVirus'
        );
      }

      Object.keys(store_service_status).forEach((key) => {
        let service = store_service_status[key].subsystem_service_id;
        if (service !== 'Reporter') {
          let isp_active = true;
          switch (service) {
            case 'ContentFilter':
              isp_active = this.global_configurations.parental_control.enabled && !this.isIoT();
              break;
            case 'AdsFree':
              isp_active = this.global_configurations.ads_free.enabled && !this.isIoT();
              break;
            case 'Activation':
              // Autonotice
              isp_active = this.global_configurations.auto_notice.enabled && !this.isIoT();
              break;
            case 'AntiBotnet':
              isp_active = this.global_configurations.threat_protection.antibotnet_enabled && !this.isIoT();
              break;
            case 'Firewall':
              // Firewall is not defined yet at ISP level on 17.1. Pending for future versions
              break;
            case 'AntiVirus':
              isp_active = this.global_configurations.threat_protection.antivirus_enabled && !this.isIoT();
              break;
            case 'AntiPhishing':
              isp_active = this.global_configurations.threat_protection.antiphishing_enabled && !this.isIoT();
              break;
            default:
              break;
          }
          if (this.isIoT() && service != 'Firewall') store_service_status[key].calculated_status = 'off';
          data[service] = {
            status: store_service_status[key].calculated_status,
            global: isp_active
          }
        }
      });

      return data;
    },
    hosts() {
      return this.$store.getters["networksecure/status/networkSecureHosts"];
    },
  },
	beforeMount() {
		this.getGlobalConfigurations();
	},
  methods: {
		...mapActions('ispServices/services', ['getGlobalConfigurations']),
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    resetModal() {
      this.$validator.reset();
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    async handleSubmit() {
      let isValidForm = await this.$validator.validateAll();
      if (!isValidForm) return;
      this.$store
        .dispatch("networksecure/status/updateServiceLocation", {
          id: this.model.hostname,
          data: { physical_location: this.model.physical_location },
        })
        .then((id) => {
          this.$store.commit(
            "setSuccess",
            `${this.t("location_for_host")} ${this.model.hostname} ${this.t(
              "has_been_changed"
            )}`
          );
          this.refresh();
        })
        .catch((err) => this.refresh());

      this.$nextTick(() => {
        this.$refs.modal.hide();
      });
    },
    handlePrevPage() {
      if (this.query) {
        ["next_id", "next_name"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
        if (this.query.prev_id) {
          this.currentPage -= 1;
          this.refresh();
        }
      }
    },
    handleNextPage() {
      if (this.query) {
        ["prev_id", "prev_name"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
        if (this.query.next_id) {
          this.currentPage += 1;
          this.refresh();
        }
      }
    },
    HandlePagination(value) {
      this.currentPage = 1;
      this.self_query.limit = value;
      ["next_id", "next_name", "prev_id", "prev_name"].forEach((item) => {
        if (this.self_query[item]) delete this.self_query[item];
      });
      this.reload();
    },
    handleSearch: _.debounce(function (value) {
      this.currentPage = 1;
      if (value) {
        this.query.field_operator = "sw";
        this.query.field_name = value.field;
        this.query.field_value = value.value;
      } else {
        ["field_operator", "field_name", "field_value"].forEach((item) => {
          if (this.query[item]) delete this.query[item];
        });
      }
      ["next_id", "next_name", "prev_id", "prev_name"].forEach((item) => {
        if (this.query[item]) delete this.query[item];
      });
      this.refresh();
    }, 500),
    reload() {
      if (this.self_query) {
        this.query = this.self_query;
        this.nextPage = 0;
        this.refresh();
      }
    },
    async refresh(wait) {
      this.updating = true;
      await this.$store
        .dispatch("networksecure/status/getNetworkSecureHosts", {
          wait: wait,
          query: this.query,
        })
        .then((res) => {
          this.updating = false;
        })
        .catch((err) => {
          this.updating = false;
        });
    },
    loadServiceStatus() {
      this.updating = true;
      this.$store
        .dispatch("networksecure/status/getServiceStatus")
        .then(() => (this.updating = false))
        .catch(() => (this.updating = false));
    },
    handleChangeLocation(data) {
      this.model = { ...{}, ...data.row };
      this.$refs.modal.show();
    },
    handleShowDetails(data) {
      // this.$router.push({path: '/ns/General/GeneralManagement/AppsStatus/' + encodeURIComponent(data.row.hostname)})
      this.$router.push({
        name:
          "Network Secure / General / Status / Host & Process Control / Detail",
        params: {
          id: data.row.hostname,
        },
      });
    },
    handleChangeServiceStatus(value, service, index) {
      this.updating = true;
      this.$store
        .dispatch("networksecure/status/setServiceStatus", {
          subsystem_service_id: service,
          status: value,
        })
        .then((res) => {
          this.loadServiceStatus();
        })
        .catch((err) => {
          this.updating = false;
        });
    },
    isIoT() {
      return this.$store.getters['settings/isIOT'];
    }
  },
};
</script>
<style>
.host-table .card-footer {
  display: none;
}

.host-table .card-header div:first-child {
  display: none;
}
</style>

