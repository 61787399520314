<template>
    <div class="array-item-picker">
        <div 
            class="array-item-picker__item"
            v-for="(item, index) in value"
            :key="`array-item-picker__item-${index}`"
            :class="checkClass(item)"
            v-show="item.label"
            @click="pickItem(index)"
        >
            {{ t(cutLabel(item.label)) }}
        </div>
    </div>
</template>
<script>
import permissions from '@/mixins/permissions'
export default {
    name: 'array-item-picker',
    mixins: [permissions],
    props: {
        value: {
            type: Array,
            default: []
        },
        labelLength: {
            type: Number,
            default: 2
        }
    },
    methods:{
        cutLabel(label){
            return label.substr(0, this.labelLength)
        },
        pickItem(index){
            if (this.permission_granted) {
                let newData = JSON.parse(JSON.stringify(this.value))
                newData[index].active = !newData[index].active
                this.$emit('change', {
                    data: newData,
                    index: index
                })
            }
        },
        checkClass(item) {
            return this.permission_granted ? {'array-item-picker__item_active' : item.active} : {'array-item-picker__item_active_disabled' : item.active , 'array-item-picker__item_inactive_disabled' : !item.active};
        }        
    }
}
</script>
<style lang="scss" scoped>
    .array-item-picker{
        position: relative;
        display: flex;
        flex-wrap: wrap;

        &__item{
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
            margin-bottom: 15px;
            border: 1px solid rgba(#fff,0.8);
            color: rgba(#fff,0.8);
            font-size: 16px;
            border-radius: 50%; 
            text-transform: capitalize;           
            transition: color .2s ease, background-color .2s ease, opacity .2s ease;
            cursor: pointer;
            &:hover{
                opacity: .7;
            }
            &:last-of-type{
                margin-right: 0;
            }

            &_active{
                background-color: #CB4B16;
                color: #fff !important;
            }

            &_active_disabled{
                background-color: #CB4B1680;
                color: #fff !important;
                cursor: default;
            }

            &_inactive_disabled{
                background-color: #D3D3D390;
                color: #fff !important;
                cursor: default;
            }
        }
    }
</style>