<template>
  <b-container fuild style="max-width: 2560px">
    <page-title-card
      :page-title="t('title')"
      :page-description="t('description')"
    >
      <template #button>
        <n-button
          class="mt-3"
          :disabled="has_errors"
          type="primary"
          size="md"
          round
          block
          @click.native="handleSave"
          :visible_for_permissions="[
                { actions: ['PUT'], path: '/subsystems/ns/filtering-logs' },
              ]"
        >
          {{ t("Save") }}
        </n-button>
      </template>
    </page-title-card>
    <b-row>
      <b-col cols="6" class="row-eq-height col-xxl-5">
        <b-card class="card-user">
          <h5 class="title">
            <i class="mr-2"><svgicon class="icon" icon="log-server" /></i>
            {{ t("Log server") }}
          </h5>
          <b-container fluid style="padding: 0px">
            <b-row no-gutters>
              <b-col
                :sm="12"
                :md="12"
                :xl="6"
                v-for="(value, name) in server_list"
                :key="name"
              >
                <b-container fluid style="padding: 0px">
                  <b-row no-gutters>
                    <b-col
                      cols="8"
                      class="form-group has-label pt-2"
                      align-self="center"
                    >
                      <h5 class="mx-auto my-auto" style="white-space: nowrap">
                        {{ t(name) }}
                      </h5>
                    </b-col>
                    <b-col cols="2" align-self="center">
                      <n-switch
                        v-model="value.enabled"
                        :visible_for_permissions="visible_for_permissions"
                        @input="toggle($event, name)"
                      />
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
          </b-container>

          <template v-slot:footer>
            <div class="hr-grey mb-2" />
            <div
              @click="handleCancel"
              v-if="!updating"
              style="cursor: pointer; display: inline"
            >
              <i class="now-ui-icons arrows-1_refresh-69" />
              {{ t("Update now") }}
            </div>
            <div v-else>
              <i class="now-ui-icons loader_refresh spin" />
              {{ t("Updating...") }}
            </div>
          </template>
        </b-card>
      </b-col>
      <b-col cols="6" class="row-eq-height col-xxl-7">
        <b-card class="card-user">
          <h5 class="title">
            <i class="mr-2"
              ><svgicon class="icon" icon="icon-filteringlogconfig1"
            /></i>
            {{ t("Filtering log configuration") }}
          </h5>

          <b-container fluid style="padding: 0px">
            <b-row no-gutters>
              <b-col cols="12">
                <h5>{{ t("Log server") }}:</h5>
              </b-col>
              <b-col cols="3">
                <fg-input class="min-windth-265" >
                  <el-select
                    class="select-primary"
                    name="log-server"
                    size="large"
                    placeholder=""
                    v-model="model.log_server"
                    :disabled="has_errors || !permission_granted"
                    @input="handleLogServerChange($event)"
                  >
                    <el-option
                      v-for="(value, name) in enabledServerList"
                      class="select-primary"
                      :value="name"
                      :label="t(name)"
                      :key="name"
                    >
                    </el-option>
                  </el-select>
                </fg-input>
              </b-col>
            </b-row>

            <b-row no-gutters>
              <b-col cols="12">
                <h5>{{ t("Record in log files") }}:</h5>
              </b-col>
              <b-col cols="3">
                <fg-input
                  :error="getError('record-type')"
                  class="min-windth-265"
                >
                  <el-select :disabled="!permission_granted"
                    class="select-primary"
                    name="record-type"
                    size="large"
                    placeholder=""
                    v-model="model.log"
                    v-validate.continues="'logRequired'"
                    @input="handleLogChange($event)"
                  >
                    <el-option
                      v-for="option in log_files_options"
                      class="select-primary"
                      :value="option.key"
                      :label="t(option.value)"
                      :key="option.key"
                    >
                    </el-option>
                  </el-select>
                </fg-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <h5>
                  {{ t("Keep detailed logs in local directory for (days)") }}:
                </h5>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="3">
                <fg-input :visible_for_permissions="visible_for_permissions"
                  v-model="model.keep"
                  id="keep"
                  name="keep"
                  v-validate="modelValidations.number"
                  @input="handleKeepChange($event)"
                  class="min-windth-265"
                  :error="getError('keep')"
                >
                </fg-input>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { Select, Option } from "element-ui";
import { Switch } from "@/components";
import permissions from '@/mixins/permissions'
import PageTitleCard from "../../../components/AsmConfigurations/PageTitleCard";

export default {
  name: "networksecure-filtering-log",
  mixins: [permissions],
  components: {
    [Switch.name]: Switch,
    [Option.name]: Option,
    [Select.name]: Select,
    PageTitleCard
  },
  props: {
    visible_for_permissions : {
      default: [{ actions: ['PUT'], path: '/subsystems/ns/filtering-logs' }]
    }
  },
  data() {
    return {
      updating: false,
      has_errors: false,
      server_list: {},
      model: {
        log_server: "",
        log: "Accesses",
        keep: 1,
      },
      modelValidations: {
        number: {
          required: true,
          numeric: true,
          min_value: 1,
        },
        required: {
          required: true,
        },
      },
    };
  },
  mounted() {
    this.loadServers();
  },
  computed: {
    log_files_options() {
      return [
        { key: "Accesses", value: "All requests" },
        { key: "Blocks", value: "Blocked requests" },
        { key: "PerPolicy", value: "As indicated in each filtering policy" },
      ];
    },
    enabledServerList() {
      let enabled = {};
      for (const name in this.server_list) {
        if (this.server_list.hasOwnProperty(name)) {
          const server = this.server_list[name];
          if (server.enabled) enabled[name] = server;
          if (server.enabled && this.model.log_server === name) {
            this.model.keep = server.keep;
            this.model.log = server.log;
          }
        }
      }
      if (Object.keys(enabled).length === 0) this.model.log_server = "";
      return enabled;
    },
  },
  created() {
    let self = this;
    this.$validator.extend("logRequired", {
      getMessage(field) {
        return `The ${field} field is required`;
      },
      validate(value, field) {
        if (
          self.server_list[self.model.log_server] &&
          self.server_list[self.model.log_server].enabled
        ) {
          return self.server_list[self.model.log_server].log !== undefined;
        }

        return true;
      },
    });
  },
  methods: {
    reset() {
      this.has_errors = false;
      this.$validator.reset();
      this.loadServers();
    },
    toggle(event, name) {
      if (event) {
        this.model.log_server = name;
        this.model.log = "Accesses";
        this.model.keep = this.server_list[name].keep;
        this.handleLogServerChange(name);
        this.handleLogChange(
          this.server_list[name].log ? this.server_list[name].log : "Accesses"
        );
      } else {
        if (this.model.log_server === name) {
          this.model.log = "Accesses";
          this.model.log_server = "";
          this.model.keep = 1;
        }
      }
      this.server_list[name].enabled = event;
      this.handleValidate();
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    async loadServers() {
      this.updating = true;
      await this.$store.dispatch(
        "networksecure/filtering_log/getNetworkSecureLogServers"
      );
      try {
        let _servers = {};
        let store_log_servers = this.$store.getters['networksecure/filtering_log/logServers'];

        for (const server of store_log_servers) {
          _servers[server.log_server] = {
            log: "Accesses",
            keep: 1,
            ...server,
          };
        }
        if(_servers.AdsFree){
          delete _servers.AdsFree
        }

        this.server_list = _servers;
        if (!this.model.log_server) {
          this.model.log_server = Object.keys(this.server_list)[0];
          this.handleLogServerChange(this.model.log_server);
        }
        this.updating = false;
      } catch (err) {
        console.error(err);
        this.updating = false;
      }
      this.handleValidate();
    },
    handleLogServerChange(server_name) {
      this.model.log = this.server_list[server_name].log;
      this.model.keep = this.server_list[server_name].keep;
    },
    handleLogChange(log) {
      this.server_list[this.model.log_server].log = log;
      this.handleValidate();
    },
    async handleKeepChange(keep) {
      this.server_list[this.model.log_server].keep = keep === "" ? "" : +keep;
      await this.handleValidate();
    },
    async handleValidate() {
      const isValid = await this.$validator.validateAll();
      this.has_errors = !isValid;
    },
    async handleSave() {
      await this.handleValidate();
      if (this.has_errors) return;

      let success = true;
      for (const server in this.server_list) {
        if (this.server_list.hasOwnProperty(server)) {
          try {
            await this.$store.dispatch(
              "networksecure/filtering_log/updateNetworkSecureLogServer",
              { ...this.server_list[server] }
            );
          } catch {
            (err) => {
              console.error(
                "Error updating network secure log server--> ",
                err
              );
              success = false;
            };
          }
        }
      }

      if (success) {
        this.$store.commit("setSuccess", this.t("logs_saved"));
        this.reset();
      }
    },
    handleCancel() {
      this.reset();
    },
  },
};
</script>
<style>
</style>
