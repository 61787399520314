<template>
  <b-container fuild style="max-width: 2560px">
    <b-row>
      <b-col class="row-eq-height">
        <page-head-actions
          :title="cluster_name"
          @refresh="refresh"
          :isRealId="this.isRealId"
        >
          <template slot="buttons">
            <n-button
              type="primary"
              size="md"
              id="btn_submit"
              round
              block
              @click.native="preSave"
              :visible_for_permissions="visible_for_permissions"
              >{{ t("Save") }}</n-button
            >
            <n-button
              type="primary"
              size="md"
              id="btn_cancel"
              round
              block
              @click.native="handleCancel"
              >{{ t("Back") }}</n-button
            >
          </template>
        </page-head-actions>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card>
          <server-form :visible_for_permissions="visible_for_permissions"
            v-model="server"
            :cluster_name="cluster_name"
            :cluster_type="cluster_type"
            @input="handleServer"
            ref="serverForm"
          >
          </server-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>      

<script>
import ServerForm from "./ServerForm";
import PageHeadActions from "@/components/PageHeadActions";

export default {
  props: ["cluster_name", "cluster_type", "server_name"],
  components: {
    ServerForm,
    PageHeadActions,
  },
  data() {
    return {
      visible_for_permissions : [{actions: ["POST"], path: "/subsystems/ns/external-servers.*"}, {actions: ["PATCH"], path: "/subsystems/ns/external-servers.*"}],
      updating: false,
      newserver: null,
      server: null,
    };
  },
  methods: {
    refresh(wait) {
      let _vm = this;
      this.updating = true;
      if (this.cluster_name && this.server_name) {
        this.$store
          .dispatch("networksecure/externalservers/getServer", {
            cluster_name: this.cluster_name,
            server_name: this.server_name,
          })
          .then((res) => {
            _vm.server = {
              name: res.name,
              mode: res.mode,
              location: res.location,
              port: res.port,
              status: res.status,
              user: res.user,
              password: res.password,
            };
            if (res.port == "" && this.cluster_name.indexOf("DNS") != -1) {
              _vm.server.port = "53";
            } else if (
              res.port == "" &&
              this.cluster_name.indexOf("SNMP") != -1
            ) {
              _vm.server.port = "162";
            }
            this.updating = false;
          })
          .catch((err) => {
            this.updating = false;
            this.$router.replace("/");
          });
      }
    },
    handleServer(data) {
      if (this.realid) {
        this.$store
          .dispatch("networksecure/externalservers/updateServer", {
            cluster_name: this.cluster_name,
            server_name: this.realid,
            data: data,
          })
          .then((res) => {
            this.$store.commit(
              "setSuccess",
              this.$t("Server") +
                " " +
                data.name +
                " " +
                this.$t("successfully updated")
            );
          });
      } else {
        let _vm = this;
        this.$store
          .dispatch("networksecure/externalservers/createServer", {
            cluster_name: this.cluster_name,
            data: data,
          })
          .then((res) => {
            _vm.newserver = res;
            this.$store.commit(
              "setSuccess",
              this.$t("Server") +
                " " +
                data.name +
                " " +
                this.$t("successfully created")
            );
            this.$router.replace({
              path: "/ns/General/GeneralManagement/ExternalServers/",
            });
          });
      }
    },
    handleCancel() {
      this.$router.replace({
        path: "/ns/General/GeneralManagement/ExternalServers/",
      });
    },
    preSave() {
      this.$refs.serverForm.save();
    },
  },
  watch: {
    newserver(newValue) {
      let _vm = this;
      this.$store
        .dispatch("networksecure/externalservers/getServer", {
          cluster_name: this.cluster_name,
          server_name: this.server.name,
        })
        .then((res) => {
          _vm.server = res;
        })
        .catch((err) => this.$router.replace("/"));
    },
  },
  computed: {
    realid() {
      return this.server_name || this.newserver;
    },
    isRealId() {
      return this.server_name != undefined ? true : false;
    },
  },
  mounted() {
    this.refresh(0);
  },
};
</script>
<style>
/* Hide 'numeric' type input arrows*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
